import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Gardens1: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren =(<>
         <span>
                        {t("This oasis of agrarian tranquility is the Gardens. Henry the Horticulturist oversees everything in this area, including the hopping bun-buns.")}
                    </span>
        <span>
                        {t("You’ll want to take a look at the ")}
            <b>{t("Seed Box")}</b>
            {t(", which shows a list of the active ")}
            <b>{t("Liquidity Pools (Gardens)")}</b>
            {t(" in Song Of Rising. You can see how much is invested in each pool, each pool’s emissions, and the expected return (using a very simplified formula) from this screen. This is where you can decide which Liquidity Pools you are interested in joining.")}
                    </span>
    </>)

    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="marketplace2" activeIndex={1} count={3} nextLable="gardens2"/>
    )

    return <TourDialog setLable={setLable} title={t("The Garden")} imgUrl='images/npc/go-E.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledGardens = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 360px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledGardensRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledGardensLeft = styled.div`
  width: 100%;
  display: flex;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledGardensRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledGardensBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledGardensPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledGardensPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledGardensBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledGardensNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 0;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Gardens1
