import React from 'react'
import { ModalProvider } from '@pancakeswap/uikit'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { LanguageProvider } from 'contexts/Localization'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    top: '80px'
  }
}));


const Providers: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      dense={false}
      classes={{
        containerAnchorOriginTopRight: classes.root,
        containerAnchorOriginTopCenter: classes.root,
        containerAnchorOriginTopLeft: classes.root,
      }}
    >
        <Provider store={store}>
          <ToastsProvider>
            <HelmetProvider>
              <ThemeContextProvider>
                <LanguageProvider>
                  <RefreshContextProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </RefreshContextProvider>
                </LanguageProvider>
              </ThemeContextProvider>
            </HelmetProvider>
          </ToastsProvider>
        </Provider>
    </SnackbarProvider>

  )
}

export default Providers
