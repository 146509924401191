import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"
import {Back} from "../../../style/UiStyle";

interface Props {
    setLable: (event) => void
}
const Congratulations: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();

    const DialogRender =( <StyledCongratulations>
        <StyledCongratulationsRow>
            <StyledCongratulationsLeft>
                <img src='images/npc/go-A.png' alt=''/>
            </StyledCongratulationsLeft>
            <StyledCongratulationsRight>
                    <span>
                        {t("You’re now a fully established player of Song Of Rising. If you have the time, I would love to show you around. There are already plenty of things to do with more to come. If you want to spend some time exploring on your own, you can come back to me any time for more information on the Bank, Marketplace, Gardens, and more.")}
                    </span>
                <span>
                        <b>{t("Would you like me to give you a quick tour now?")}</b>
                    </span>
            </StyledCongratulationsRight>
        </StyledCongratulationsRow>
        <StyledCongratulationsBotton>
            <StyledCongratulationsAlready onClick={()=>{setLable('playing')}}>
                <span>{t("No, I'd like to explore on my own")}</span>
            </StyledCongratulationsAlready>
            <StyledCongratulationsChoose>
                <StyledCongratulationsNext>
                    <span>  <Link to='/Tour'>{t("Quick Tour")}</Link></span>
                </StyledCongratulationsNext>
            </StyledCongratulationsChoose>
        </StyledCongratulationsBotton>
    </StyledCongratulations>)
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("Congratulations!")}/>
    }

const StyledCongratulations = styled.div`
  width: 100%;
  padding: 30px 30px 20px 30px;
  
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 330px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledCongratulationsRow = styled.div`
  //display: flex;
  //flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledCongratulationsLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   width: 200px;
  // }
  & img {
    object-fit: cover;
    //height: 120px;
    width: 100%;
  }
`

const StyledCongratulationsRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`
const StyledCongratulationsBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledCongratulationsAlready = styled.div`
  width: 240px;
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledCongratulationsChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
    padding: 0;
  }
`
const StyledCongratulationsNext = styled(Back)`
  //width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  //border: 1px solid ${({theme})=> theme.expandColor.color.active};
  color: ${({theme})=> theme.expandColor.color.color};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
  & span {
    padding:0 1px 0 0;
  }
`
export default Congratulations
