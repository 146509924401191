import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'


interface Props {
    setLable: (event) => void
}
const Gardens2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren =(
        <>
         <span>
                        {t("If you’ve already purchased Seeds from ")}
             <b>{t("Druid Lazarus")}</b>
             {t(" in the Marketplace, you can deposit them in the ")}
             <b>{t("Seed Box")}</b>
             {t(" here. Henry will plant them immediately, and you’ll earn rewards over time as new RUBYs work their way up from the depths.")}
                    </span>
            <span>
                        {t("The Horticulturist will lovingly tend your plants until all of your rewards are released. You can check the ")}
                <b>{t("Seed Box")}</b>
                {t("Harvest")}
                <b>{t("Liquidity Pools (Gardens)")}</b>
                {t(" area to view and claim your rewards. When buying Seeds and planting in the Gardens, remember that withdrawal fees apply and that a portion of rewards are time-locked. Planting in the Gardens is a powerful tool in Song Of Rising, so I suggest spending some time to learn about Liquidity Pools and the time-locked rewards system if you aren’t already familiar.")}
                    </span>
        </>
    )
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="gardens1" activeIndex={2} count={3} nextLable="gardens3"/>
    )

    return <TourDialog setLable={setLable} title={t("The Garden")} imgUrl='images/npc/go-E.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledGardens = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 425px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledGardensRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledGardensLeft = styled.div`
  width: 100%;
  display: flex;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledGardensRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledGardensBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledGardensPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledGardensPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledGardensBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledGardensNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 0;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Gardens2
