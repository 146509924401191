import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Jeweler: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren =(<>
         <span>
                        {t("We’ve now arrived at the Planner! If you’re thinking about holding onto your RUBY for a while, and if you’d like to earn rewards while doing so, this is the right place to be. You can speak to ")}
             <b>{t("Planner Ian")}</b>
             {t(" to deposit RUBY into his vault.")}
             <span>
                        {t("While your RUBY is deposited, a portion of the fees from the decentralized exchange and a portion of fees from in-game RUBY transactions are shared with you. These rewards are shared based on your share of all deposits with the Planner, so the more RUBY you have in his vaults, the more rewards you will accrue.")}
                    </span>
                    </span>
        <span>
                        {t("Unlike in the Gardens, these rewards are unlocked instantly. You can, of course, also withdraw your funds from the Jeweler at any time and there is no withdrawal fee.")}
                    </span>
    </>)
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="gardens3" activeIndex={1} count={1} nextLable="tavern1" isNextMap nextTitle="The Heros Market"/>
    )
    return <TourDialog setLable={setLable} title={t("The Planner")} imgUrl='images/npc/go-D.png' btnChildren={btnChildren} textChildren={textChildren}/>

}

const StyledJeweler = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 425px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledJewelerRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledJewelerLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledJewelerRight = styled.div`
  width: 100%;
  display: flex;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  flex-direction: column;
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledJewelerBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledJewelerBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledJewelerNext = styled.div`
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-top: 0;
  }
  & span {
    padding:0 1px 0 0;
  }
`
export default Jeweler
