import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from "../../../components/Dialog";
import {Next} from "../../../style/UiStyle";
import BackOrNext from "../../../components/BackOrNext"

interface Props {
    setLable: (event) => void
}
const Harmony2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender =(
        <StyledHarmony>
            <StyledHarmonyRow>
                <StyledHarmonyLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledHarmonyLeft>
                <StyledHarmonyRight>
                    <span>
                        {t("Still hanging in there, adventurer? We’re making great progress, and Song Of Rising is just around the corner!")}
                    </span>
                    <span>
                        {t("We need to ")}
                        <b>{t("transfer OKT")}</b>
                        {t(" tokens that you own into your Metamask wallet so that you can use them inside the game.")}
                    </span>
                </StyledHarmonyRight>
            </StyledHarmonyRow>

            <StyledHarmonyContent>
                <span>
                    {t("If your vendor already asked for your wallet address, they should have deposited the tokens already. You can click on Metamask to see your token balance and skip this step if the tokens are already in your wallet. Otherwise, you’ll need to use the options built in with your vendor to transfer the OKT tokens to your private Metamask wallet.")}
                </span>
                <span>
                    {t("Each vendor handles this differently so I can’t give you exact instructions. Generally, you’ll select an option like “send” or “transfer,” choose your token and the amount to move, and then enter the target wallet address. If you haven’t done this before and are worried about making a mistake, you can always ")}
                    <b>{t("transfer a small amount first")}</b>
                    {t(" to make sure it works, before moving your full balance. Even I, sage as I am, often do this as a precautionary measure. There’s no rush, so just let me know when you’ve transferred your tokens.")}
                </span>

            </StyledHarmonyContent>
            <BackOrNext setLable={setLable} backLable="harmony1" activeIndex={2} count={3} nextLable="harmony3" />

        </StyledHarmony>
    )
    return <Dialog  DialogChildren={DialogRender} title={t("Harmony ONE Tokens")}/>
    }

const StyledHarmony = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  color: ${({theme})=> theme.expandColor.color.color10};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 500px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledHarmonyRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledHarmonyRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledHarmonyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledHarmonyBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`
const StyledHarmonyPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledHarmonyAlready = styled.div`
  width: 240px;
`
const StyledHarmonyChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledHarmonyBack = styled.div`
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledHarmonyNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 10px;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Harmony2
