import React from 'react'
import styled from 'styled-components'

const ContectRow: React.FC = () => {


    return (
      <ContactsRow>
        {/*
          youtube: https://www.youtube.com/channel/UCRxPEfKxIqnbWoTkAhpSVTA/featured
          github: https://github.com/songofrising
          */}
        {/*
          telegram
           <a href="https://t.me/Vip_Vipcoin" target="_blank" rel="noreferrer">
            <ContactItem>
              <img src='images/home/icon1.png' alt=''/>
            </ContactItem>
          </a>
          */}

        {/* <a href="https://merlinchain.io" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon7.svg" alt="" />
          </ContactItem>
        </a> */}

        <a href="https://twitter.com/songofrising" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon2.svg" alt="" />
          </ContactItem>
        </a>
        {/*
          ins
           <a href="https://discord.gg/3KjZySdfCV" target="_blank" rel="noreferrer">
            <ContactItem>
              <img src='images/home/1-6.png' alt=''/>
            </ContactItem>
          </a>

          */}

        <a href="https://discord.gg/3KjZySdfCV" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon4.svg" alt="" />
          </ContactItem>
        </a>
        {/*
          facebook
          <a href="https://www.facebook.com/vipvipcoin" target="_blank" rel="noreferrer">
            <ContactItem>
              <img src='images/home/1-8.png' alt=''/>
            </ContactItem>
          </a>
          */}

        <a href="https://www.youtube.com/channel/UCRxPEfKxIqnbWoTkAhpSVTA/featured" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon9.svg" alt="" />
          </ContactItem>
        </a>
        <a href="https://github.com/songofrising" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon8.svg" alt="" />
          </ContactItem>
        </a>
        <a href="https://medium.com/@songofrising" target="_blank" rel="noreferrer">
          <ContactItem>
            <img src="images/home/icon6.svg" alt="" />
          </ContactItem>
        </a>
      </ContactsRow>
    )
}

const ContactsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme})=>theme.mediaQueries.sm}  {
    //margin-left: 100px;
  }
`
const ContactItem = styled.div`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    & img {
      position: absolute;
      left: -30px;
      filter: drop-shadow(30px 0px ${({theme})=>theme.expandColor.color.color5});
    }
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export default ContectRow