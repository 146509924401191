import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'

import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'


interface Props {
    setLable: (event) => void
}
const Gardens3: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren =(<>
    <span>
                        {t("Liquidity pools and time-locked rewards are a fairly complex topic, and you can read more in our ")}
        {/* <a href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens" target="_blank" rel="noreferrer">{t("Docs")}</a> */}
                    </span>
        </>)

     const btnChildren = (
        <BackOrNext setLable={setLable} backLable="gardens2" activeIndex={3} count={3} nextLable="jeweler" isNextMap nextTitle="The Planner"/>
    )
    return <TourDialog setLable={setLable} title={t("The Garden")} imgUrl='images/npc/go-E.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

export default Gardens3
