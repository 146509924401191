import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Tavern2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren =(<>
     <span>
                        {t("Here in the Hero's Market, ")}
         <b>{t("Barkeep Kessing")}</b>
         {t(" will allow you to view any Hero’s card or send a Hero to another player, while ")}
         <b>{t("Selena")}</b>
         {t(", the Agent, can give you a list of Heroes for purchase. Once you have Heroes of your own, you can come back here and offer them for hire or for sale as you progress further in the game.")}
                    </span>
    </>)
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="tavern1" activeIndex={2} count={2} nextLable="professions" isNextMap nextTitle="The Pioneer"/>
    )
    return <TourDialog setLable={setLable} title={t("The Hero's Market")} imgUrl='images/npc/go-F.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledTavern = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 360px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledTavernRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
`
const StyledTavernLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledTavernRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledTavernBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledTavernPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledTavernPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledTavernBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledTavernNext = styled.div`
  width: 160px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
`
export default Tavern2
