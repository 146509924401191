import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"
import {GameBtnTheme} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void
}
const Disclaimer: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation()
    const DialogRender = (
        <StyledDisclaimer >
            <span className='content'>{t("All investment contains risk. The value of tokens in the fund may fluctuate and historical performance is not indicative of future returns. The value of locked tokens valued today does not guarantee an equal value at the time of unlocking or sale. The value of tokens or NFTs is subject to market conditions. Investments are made at your own risk.")}</span>
            <span className='content'>{t("All digital accounts are susceptible to fraud. Protect all account login information and do not share with others. Beware of scams or phishing attempts which may occur in various forms, including from bad actors on official Discord pages and social media sites. Employees affiliated with Song Of Rising will NEVER request wallet seed phrases or passwords. Song Of Rising does not condone peer-to-peer transactions; conduct these at your own risk.")}</span>
            <span className='content'>{t("This tutorial is intended as a basic overview of the steps needed to create a Song Of Rising account. Transfers of tokens or funds from wallet to wallet carry significant risk if proper steps are not followed. Viewing vendor-specific guides on deposits, purchases, and inbound/outbound transfers is encouraged. Test transfers in small amounts are always encouraged as a best practice.")}</span>
            <div className='DisclaimerBtn'>
                <StyledUnderstand onClick={()=>{setLable('GO')}}>
                 <span>{t('I UNDERSTAND')}</span>
                </StyledUnderstand>
            </div>
        </StyledDisclaimer>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title="Disclaimer" />
    }

const StyledDisclaimer = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 500px;
  }
  & span {
    padding: 10px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
  }
  & .content {
    font-size: 16px;
    color: ${({theme})=> theme.expandColor.color.color10};
  }
  & .DisclaimerBtn {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
`
const StyledUnderstand = styled(GameBtnTheme)`
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
  color: ${({theme})=> theme.expandColor.color.primary};
  cursor: pointer;
`
export default Disclaimer
