import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'
import {Back, GameBtnTheme, Next} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void
}
const Joining: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren = (<>
          <span>
                        {t("Now I’ve shown you all that there is to see in Song Of Rising. I have enjoyed guiding you, and I expect to see you do great things!")}
                    </span>
        <span>
                        {t("I encourage you to come back to this tutorial or to the official Song Of Risingdocuments or the associated FAQ pages at any time to review this information.")}
                    </span>
        <span>
                        {t("Also, we have a thriving ")}
            <a href="https://beta.songofrising.com/" target="_blank" rel="noreferrer">{t("Discord community")}</a>
            {t(" where the Dev Team, and fellow players can answer questions, offer advice, and enjoy the experience of Song Of Rising together. I hope you’ll join us on Discord and our communities on any other social media that you use. Once more, welcome to the community! Nolan out!")}
                    </span>
    </>)
    const btnChildren = (
        <StyledJoiningBotton>
            <StyledJoiningAlready onClick={()=>{setLable('welcome')}}>
                <span>{t("Take the tour again")}</span>
            </StyledJoiningAlready>
            <StyledJoiningChoose>
                <StyledJoiningNext>
                 <span><a href="https://beta.songofrising.com/" target="_blank" rel="noreferrer">{t("Start Playing")}</a></span>
                </StyledJoiningNext>
            </StyledJoiningChoose>
        </StyledJoiningBotton>
    )
    return <TourDialog setLable={setLable} title={t("Joining")} imgUrl='images/npc/go-F.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledJoiningBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledJoiningAlready = styled(Back)`
  //width: 100%;
  color: ${({theme})=> theme.expandColor.color.color10};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 240px;
  }
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledJoiningChoose = styled.div`
  //width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
    padding: 0;
  }
`
const StyledJoiningNext = styled(Next)`
  //width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  //border: 1px solid ${({theme})=> theme.expandColor.color.active};
  color: ${({theme})=> theme.expandColor.color.color10};
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
  & span {
    padding:0 1px 0 0;
  }
`
export default Joining
