import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../BackOrNext"
import Dialog from  "../Dialog"

interface Props {
    setLable: (event) => void
    textChildren?:React.ReactNode
    btnChildren?:React.ReactNode
    imgUrl:string
    title:string
}
const TourDialog: React.FC<Props> = ({title,setLable,textChildren,imgUrl,btnChildren}) => {
    const { t } = useTranslation();
    const DialogRender = (
        <StyledMap>
            <StyledMapRow>
                <StyledMapLeft>
                    <img src={imgUrl} alt=''/>
                </StyledMapLeft>
                <StyledMapRight>
                    {textChildren}
                </StyledMapRight>
            </StyledMapRow>
            {btnChildren}

        </StyledMap>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={title}/>
}

const StyledMap = styled.div`
  width: 100%;
  padding: 30px 30px 0 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 330px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMapRow = styled.div`
  // display: flex;
  // flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledMapLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & img {
    object-fit: cover;
    //height: 150px;
    width: 100%;
  }
`

const StyledMapRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

export default TourDialog
