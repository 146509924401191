import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from "../../../components/Dialog";
import {Next} from "../../../style/UiStyle";


interface Props {
    setLable: (event) => void,
    metamaskNext: (event) => void
}
const Metamask1: React.FC<Props> = ({setLable, metamaskNext}) => {
    const { t } = useTranslation();
    const [page, setPage]= useState(1);
    const leftPage = (): void =>{
        const num = page - 1 >= 1 ? page - 1 : 3;
        setPage(num);
    }
    const rightPage = (): void =>{
        const num = page + 1 <= 3 ? page + 1 : 1;
        setPage(num);
    }
    const DialogRender =(
        <StyledMetamask>
            <StyledMetamaskRow>
                <StyledMetamaskLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledMetamaskLeft>
                <StyledMetamaskRight>
                    <span>
                        {t("Before you can accompany me into the song of rising, you will need a browser extension called ")}
                        <b>{t("Metamask")}</b>
                        {t(" wallet. You can ")}
                        {/* <a href="https://metamask.io/" target="_blank" rel="noreferrer">{t("click here to download the Metamask extension from their website.")}</a> */}</span>
                </StyledMetamaskRight>
            </StyledMetamaskRow>
            <StyledCarousel>
                <StyledCarouselLeft onClick={leftPage}>
                    <img src="images/default/btn/8-1.png" alt=''/>
                </StyledCarouselLeft>
                <StyledCarouselCenter>
                    <StyledCarouselImg>
                        <img src='images/logo/rising.png' alt='' className={`${page === 1 ? 'show':'hide'}`}/>
                        <img src='images/npc/go-A.png' alt='' className={`${page === 2 ? 'show':'hide'}`}/>
                        <img src='images/npc/go-B.png' alt='' className={`${page === 3 ? 'show':'hide'}`}/>
                    </StyledCarouselImg>
                </StyledCarouselCenter>
                <StyledCarouselRight onClick={rightPage}>
                    <img src="images/default/btn/8-2.png" alt=''/>
                </StyledCarouselRight>
            </StyledCarousel>
            <StyledCarouselPoint>
                <StyledCarouselPointDiv className={`${page === 1 ? 'show':'hide'}`} onClick={()=>{setPage(1)}}/>
                <StyledCarouselPointDiv className={`${page === 2 ? 'show':'hide'}`} onClick={()=>{setPage(2)}} />
                <StyledCarouselPointDiv className={`${page === 3 ? 'show':'hide'}`} onClick={()=>{setPage(3)}} />
            </StyledCarouselPoint>
            <StyledMetamaskContent>
                <span>{t("Click the blue download button and, on the following page, select your browser and download Metamask. Follow any prompts to enable the extension and accept security permissions. Once you see the welcome screen, tell me so we can move ahead.")}</span>
            </StyledMetamaskContent>
            <StyledMetamaskBotton>
                <StyledMetamaskAlready onClick={metamaskNext}>
                    <span>{t("I have all of these already.")}</span>
                </StyledMetamaskAlready>
                <StyledMetamaskPage>
                    <span>{t("1/3")}</span>
                    <StyledMetamaskPageDiv>
                        <div className='show'/>
                        <div className='hide'/>
                        <div className='hide'/>
                    </StyledMetamaskPageDiv>
                </StyledMetamaskPage>
                <StyledMetamaskChoose>
                    <StyledMetamaskNext onClick={()=>{setLable('metamask2')}}>
                        <span>{t("Next")}</span>
                        <img src="/images/default/btn/4-4.png" alt="" className="left"/>
                    </StyledMetamaskNext>
                </StyledMetamaskChoose>
            </StyledMetamaskBotton>
        </StyledMetamask>
    )
    console.log('page', page);
    return <Dialog  DialogChildren={DialogRender} title={t("Metamask")}/>
    }

const StyledMetamask = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 580px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMetamaskRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMetamaskLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledMetamaskRight = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & a {
    text-decoration: underline;
    cursor: pointer;
    color: ${({theme})=> theme.expandColor.color.active};
  }
`


const StyledCarousel = styled.div`
  width: 100%;
  height: 140px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
    height: 200px;
    padding: 0;
  }
`
const StyledCarouselLeft = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9c4c5;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: #807d7d;
    transition: all 1s ease;
  }
`

const StyledCarouselRight = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(201, 196, 197, .6);
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
  & img{
    width: 15px;
  }
  &:hover {
    background-color: rgba(128,125,125,.6);
    transition: all 1s ease;
  }
`
const StyledCarouselCenter = styled.div`
  width: 80%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 580px;
    height: 200px;
  }
`
const StyledCarouselImg = styled.div`
  width: 80%;
  height: 100px;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 520px;
    height: 200px;
  }
  & img {
    object-fit: cover;
    height: 100px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    ${({theme}) => theme.mediaQueries.sm} {
      height: 200px;
    }
    &.show {
      top: 50%;
      visibility: visible;
      opacity: 1;
      transition: all .5s ease;
    }
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }
`
const StyledCarouselPoint = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
`
const StyledCarouselPointDiv = styled.div`
  width: 10px;
  height: 10px;
  margin: 10px 5px;
  border-radius: 50%;
  cursor: pointer;
  &.show {
    background-color: #807d7d;
    transition: all 1s ease;
  }
  &.hide {
    background-color: #c9c4c5;
  }
`
const StyledMetamaskContent = styled.div`
  font-size: 16px;
`

const StyledMetamaskBotton = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`
const StyledMetamaskAlready = styled.div`
  width: 100%;
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 240px;
  }
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledMetamaskPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledMetamaskPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMetamaskChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledMetamaskNext = styled(Next)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  padding-left: 10px;
  //background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Metamask1
