import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'

import Let from "./Let";
import Disclaimer from "./Disclaimer";
import KnowYou from "./KnowYou";
import Browser from "./Browser";
import Metamask1 from "./Metamask1";
import Metamask2 from "./Metamask2";
import Metamask3 from "./Metamask3";
import Harmony1 from "./Harmony1";
import Harmony2 from "./Harmony2";
import Harmony3 from "./Harmony3";
import Congratulations from "./Congratulations";
import Playing from "./Playing";


const Go: React.FC = () => {
    const { t } = useTranslation()
    const [lable, setLable]= useState('GO');
    const [browsers, setBrowsers]= useState('');
    const [metamask, setMetamask]= useState('');
    const [harmony, setHarmony]= useState('');
    const knowYouNext = (event): void =>{
        if (browsers && metamask && harmony) {
            if (browsers === '2') {
                setLable('browsers');
            } else if (metamask === '2') {
                setLable('metamask1');
            } else if (harmony === '2') {
                setLable('harmony1');
            } else {
                setLable('congratulations');
            }
        } else {
            message.warning(t('Please select one of each option.'));
        }
    }
    const browserNext = (event): void =>{
        if (metamask === '2') {
            setLable('metamask1');
        } else if (harmony === '2') {
            setLable('harmony1');
        } else {
            setLable('congratulations');
        }
    }
    const metamaskNext = (event): void =>{
        if (harmony === '2') {
            setLable('harmony1');
        } else {
            setLable('congratulations');
        }
    }
    console.log('lable', lable)
    console.log('browsers', browsers)
    console.log('metamask', metamask)
    console.log('harmony', harmony)
    return (
        <StyledGO>
            <StyledGO1 className={`${lable === 'GO' ? 'show':'hide'}`}>
                <Let setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'Disclaimer' ? 'show':'hide'}`}>
                <Disclaimer setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'KnowYou' ? 'show':'hide'}`}>
                <KnowYou setLable={setLable} browsers={browsers} metamask={metamask} harmony={harmony} setBrowsers={setBrowsers} setMetamask={setMetamask} setHarmony={setHarmony} knowYouNext={knowYouNext}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'browsers' ? 'show':'hide'}`}>
                <Browser browserNext={browserNext}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'metamask1' ? 'show':'hide'}`}>
                <Metamask1 setLable={setLable} metamaskNext={metamaskNext}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'metamask2' ? 'show':'hide'}`}>
                <Metamask2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'metamask3' ? 'show':'hide'}`}>
                <Metamask3 setLable={setLable} metamaskNext={metamaskNext}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'harmony1' ? 'show':'hide'}`}>
                <Harmony1 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'harmony2' ? 'show':'hide'}`}>
                <Harmony2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'harmony3' ? 'show':'hide'}`}>
                <Harmony3 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'congratulations' ? 'show':'hide'}`}>
                <Congratulations setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'playing' ? 'show':'hide'}`}>
                <Playing setLable={setLable}/>
            </StyledGO1>
        </StyledGO>
        )
    }
const StyledGO = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
`
const StyledGO1 = styled.div`
  position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
 
  ${({theme}) => theme.mediaQueries.sm} {
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
  }
  &.show {
    z-index: 5;
    visibility: visible;
    opacity: 1;
    transition: all .5s ease;
    ${({theme}) => theme.mediaQueries.sm} {
      top: 50%;
    }
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
`


export default Go
