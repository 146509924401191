import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import Dialog from  "../../../components/Dialog"

interface Props {
    setLable: (event) => void
}
const Map1: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender = (
        <StyledMap>
            <StyledMapRow>
                <StyledMapLeft>
                    <img src='images/npc/go-C.png' alt=''/>
                </StyledMapLeft>
                <StyledMapRight>
                    <span>
                        {t("The main view in Song Of Rising is the World Map.")}
                    </span>
                    <span>
                        {t("You can ")}
                        <b>{t("zoom in and out")}</b>
                        {t(" with your mouse wheel or pinch zoom on a mobile device. Navigate by holding the left mouse button and dragging or panning with your finger on a mobile device. Visit any specific location with a click or tap.")}
                    </span>
                </StyledMapRight>
            </StyledMapRow>
            <BackOrNext setLable={setLable} backLable="welcome" activeIndex={1} count={3} nextLable="map2"/>

        </StyledMap>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("World Map")}/>
    }

const StyledMap = styled.div`
  width: 100%;
  padding: 30px 30px 0 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 330px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMapRow = styled.div`
  // display: flex;
  // flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledMapLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & img {
    object-fit: cover;
    //height: 150px;
    width: 100%;
  }
`

const StyledMapRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledMapBotton = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`
const StyledMapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledMapPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMapBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledMapNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 0;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Map1
