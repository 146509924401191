import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Go from "./components/Go";

const Tour: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledBg>
        <Go/>
    </StyledBg>
  )
}
const StyledBg = styled.div`
  background-image: url(../images/home/1-0.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  image-rendering: pixelated;
  height: 100vh;
  width: 100vw;
  background-color: ${({theme})=> theme.expandColor.color.black};
`
export default Tour
