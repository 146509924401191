import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from "../../../components/Dialog";
import {Next} from "../../../style/UiStyle";
import BackOrNext from "../../../components/BackOrNext"

interface Props {
    setLable: (event) => void
}
const Harmony3: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender =(  <StyledHarmony>
        <span className='content'>
                {t("Excellent! Now that Metamask is installed and your OKT tokens are in your Metamask wallet, you can finally enter Song Of Rising. From the homepage, you’ll just click ")}
            <b>{t("Start Playing")}</b>
            {t(" and the game will prompt you to create an account name and select an avatar.")}
            </span>
        <StyledHarmonyRow>
            <StyledHarmonyLeft>
                <img src='images/npc/1.png' alt=''/>
            </StyledHarmonyLeft>
            <StyledHarmonyRight>
                <img src='images/npc/2.png' alt=''/>
            </StyledHarmonyRight>
        </StyledHarmonyRow>

        <StyledHarmonyContent>
                <span>
                    {t("You might be surprised to see that you don’t have to create any login credentials. This is because your Song Of Rising account is linked to your Metamask. Any time you log in from a browser that has Metamask installed (and you’re logged in to your wallet, of course), Song Of Risingwill recognize you and load your profile.")}
                </span>
        </StyledHarmonyContent>
        <BackOrNext setLable={setLable} backLable="harmony2" activeIndex={3} count={3} nextLable="congratulations" />

    </StyledHarmony>)
    return <Dialog  DialogChildren={DialogRender} title={t("Harmony ONE Tokens")}/>
    }

const StyledHarmony = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 510px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
  & .content {
    font-size: 16px;
  }
`

const StyledHarmonyRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledHarmonyRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & img {
    object-fit: cover;
    height: 200px;
  }
`

const StyledHarmonyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledHarmonyBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`
const StyledHarmonyPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledHarmonyAlready = styled.div`
  width: 240px;
`
const StyledHarmonyChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledHarmonyBack = styled.div`
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledHarmonyNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 10px;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Harmony3
