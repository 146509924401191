import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"
import {GameBtnTheme} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void
}
const Let: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation()
    const DialogRender = (
        <>
            <StyledLetRow>
                <StyledLetRight>
                    <img src='images/npc/1.png' alt=''/>
                </StyledLetRight>
                <StyledLetLeft>
                    <span className="info">{t("Hi! I'm Nolan the Sage. I hear you're headed to the Song Of Rising. Exciting! Let's get you ready for the journey.")}</span>
                    <StyledLetBtn onClick={() => {
                        setLable('KnowYou')
                    }}><span>{t("LET'S GO!")}</span></StyledLetBtn>
                    <StyledDisclaimerBtn>
                        <StyledDisclaimerBtnText onClick={() => {
                            setLable('Disclaimer')
                        }}>
                            <span>{t('Disclaimer')}</span>
                        </StyledDisclaimerBtnText>
                    </StyledDisclaimerBtn>
                </StyledLetLeft>

            </StyledLetRow>

        </>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} isLogo/>
    }
const StyledLet = styled.div`
  width: 100%;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 280px;
    padding: 50px;
  }
`
const StyledLetRow = styled.div`
  z-index: 2;
  width: 100%;
  //padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 550px;
    height: 240px;
    flex-direction: row ;
   
    //padding: 50px;
  }
`
const StyledLetLeft = styled.div`
  //width: 70%;
  height: 200px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-left: 10px;
  position: relative;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 500px;
    width: 320px;
    padding-left: 0;
  }
  & span {
    font-size: 16px;
    text-align: justify;
   
  }
  & .info {
    color: ${({theme})=> theme.expandColor.color.color10};
  }
`
const StyledLetBtn = styled(GameBtnTheme)`
  width: 80%;
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  color: ${({theme})=> theme.expandColor.color.color6};
  margin-top: 30px;
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
    font-size: 30px;
  }
`

const StyledDisclaimerBtnText = styled.div`
  text-decoration: underline;
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-top: 0;
  }
  margin-top: 30px;
`

const StyledLetRight = styled.div`
  //width: 20%;
  //width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 300px;
   
  }
  & img {
    width: 200px;
  }
  
`
const StyledDisclaimerBtn = styled.div`
  width: 100px;
  position: absolute;
  padding: 15px;
  color: ${({theme})=> theme.expandColor.color.active};
  font-size: 16px;
  right: 20px;bottom:  0px;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 500px;
  }
`
export default Let
