import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Marketplace2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren = (<>
      <span>
                        {t("You may have noticed ")}
          <b>{t("Druid Lazarus")}</b>
          {t(", who’s also here in the Marketplace. Lazarus helps you pair tokens and invest in Liquidity Pools, which are a key part of Song Of Rising. In order to get started, you can speak to Druid Lazarus to buy ")}
          <b>{t("Seeds")}</b>
          {t(". You buy Seeds by investing an equal value of RUBY and another token. As you already know, you can trade for those tokens with Matoya. Once you own seeds, you can head to the Gardens to have them planted.")}
                    </span>
        <span>
                        {t("Liquidity Pooling can be a powerful tool to generate more RUBY. I’ll explain more about this process in the Gardens, so when you’re ready, let’s head there.")}
                    </span>
    </>)
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="marketplace1" activeIndex={2} count={2} nextLable="gardens1" isNextMap nextTitle="The Garden"/>
    )

    return <TourDialog setLable={setLable} title={t("Marketplace")} imgUrl='images/npc/go-D.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

export default Marketplace2
