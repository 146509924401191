import React from 'react'
import styled from 'styled-components'

import { useTranslation } from 'contexts/Localization'
import Page from 'components/layout/Page'
import RoadMap from './components/RoadMap'
import Top from './components/Top'
import Team from './components/Team'
import Footer from './components/Footer'

const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <Top />
      <RoadMap/>
      <Team/>
      <Footer/>
    </Page>
  )
}

export default Home
