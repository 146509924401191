import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import Dialog from "../../../components/Dialog";
import {Back, Next} from "../../../style/UiStyle";

interface Props {
    setLable: (event) => void,
    metamaskNext: (event) => void,
}
const Metamask3: React.FC<Props> = ({setLable, metamaskNext}) => {
    const { t } = useTranslation();
    const DialogRender =(
        <StyledMetamask>
            <StyledMetamaskRow>
                <StyledMetamaskLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledMetamaskLeft>
                <StyledMetamaskRight>
                    <span>{t("Nice job! Do you know your login information and your seed phrase? Make sure you keep them safe!")}</span>
                    <span>{t("Our next step is to change some settings inside your Metamask wallet.")}</span>
                </StyledMetamaskRight>
            </StyledMetamaskRow>

            <StyledMetamaskContent>
                <span>
                    {t("This is easy, because I’m going to give you the settings to copy and paste,")}
                    {/* <a href="https://docs.harmony.one/home/network/wallets/browser-extensions-wallets/metamask-wallet" target="_blank" rel="noreferrer">{t("direct from Harmony’s documentation page")}</a> */}
                    {t(".You’ll start this process by opening the extension and clicking at the top where it says Ethereum Mainnet. In the dropdown menu, choose ")}
                    <b>{t("Custom RPC")}</b>
                    {t(" and then copy/paste the following information:")}
                </span>
                <span>
                    {t("Network Name: Harmony Mainnet")}
                </span>
                <span>
                    {t("New RPC URL: https://api.harmony.one")}
                </span>
                <span>
                    {t("Chain ID: 1666600000")}
                </span>
                <span>
                    {t("Currency symbol: ONE")}
                </span>
                <span>
                    {t("Block Explorer URL: https://explorer.harmony.one/")}
                </span>

            </StyledMetamaskContent>
            {false&&<StyledMetamaskImg>
                <img src='images/home/1-0.png' alt=''/>
                <img src='images/home/3-1.png' alt=''/>
            </StyledMetamaskImg>}
            <StyledMetamaskBotton>
                <StyledMetamaskBack onClick={()=>{setLable('metamask2')}}>
                    <img src="/images/default/btn/3-4.png" alt="" className="left"/>
                    <span>{t("Back")}</span>
                </StyledMetamaskBack>
                <StyledMetamaskPage>
                    <span>{t("3/3")}</span>
                    <StyledMetamaskPageDiv>
                        <div className='hide'/>
                        <div className='hide'/>
                        <div className='show'/>
                    </StyledMetamaskPageDiv>
                </StyledMetamaskPage>

                <StyledMetamaskChoose>

                    <StyledMetamaskNext onClick={metamaskNext}>
                        <span>{t("Next")}</span>
                        <img src="/images/default/btn/4-4.png" alt="" className="left"/>
                    </StyledMetamaskNext>
                </StyledMetamaskChoose>
            </StyledMetamaskBotton>
        </StyledMetamask>
    )
    return <Dialog  DialogChildren={DialogRender} title={t("Metamask")}/>
    }

const StyledMetamask = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 700px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMetamaskRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMetamaskLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledMetamaskRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledMetamaskContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
  & a {
    text-decoration: underline;
    cursor: pointer;
    color: ${({theme})=> theme.expandColor.color.active};
  }
`

const StyledMetamaskBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMetamaskImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & img {
    object-fit: cover;
    width: 240px;
  }
`
const StyledMetamaskPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`
const StyledMetamaskPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMetamaskAlready = styled.div`
  width: 240px;
`
const StyledMetamaskChoose = styled.div`
  //width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledMetamaskBack = styled(Back)`
  color: ${({theme})=> theme.expandColor.color.color10};
  text-decoration: underline;
  cursor: pointer;
  & .left{
    width: 20px;
    z-index: 1;
    margin-right: 10px;
  }
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledMetamaskNext = styled(Next)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 10px;
  padding-left: 10px;
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Metamask3
