import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from "../../../components/Dialog";
import {Next} from "../../../style/UiStyle";

interface Props {
    browserNext: (event) => void
}
const Browser: React.FC<Props> = ({browserNext}) => {
    const { t } = useTranslation()
    const DialogRender = (
        <StyledBrowser>
            <StyledBrowserRow>
                <StyledBrowserLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledBrowserLeft>
                <StyledBrowserRight>
                    <StyledBrowserCard>

                        <span>
                                    {t("To get started, you will need to have one of these browsers installed on your computer. We recommend ")}
                            <b>{t("Google Chrome")}</b>
                            {t(" or Brave, but once you have a browser above installed, let me know and we can move to the next step.")}
                                </span>
                    </StyledBrowserCard>
                </StyledBrowserRight>
            </StyledBrowserRow>
            <StyledBrowserBotton>
                <StyledBrowserNext onClick={browserNext}>
                    <span>{t("Next")}</span>
                    <img src="/images/default/btn/4-4.png" alt="" className="left"/>
                </StyledBrowserNext>
            </StyledBrowserBotton>
        </StyledBrowser>
    )
    return <Dialog  DialogChildren={DialogRender} title={t("Browser Setup")}/>
    }

const StyledBrowser = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 320px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
  }
`

const StyledBrowserRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledBrowserLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
`

const StyledBrowserRight = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
`
const StyledBrowserCard = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledBrowserImg = styled.div`
  display: flex;
  & img {
    padding: 5px;
  }
`
const StyledBrowserBotton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledBrowserNext = styled(Next)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  padding-left: 10px;
  //background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Browser
