import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import AOS from 'aos'
import axios from 'axios'
import Timer from 'react-compound-timer'
import whitelist from './whitelist'

const Top: React.FC = () => {
  const { t } = useTranslation()
  AOS.init({
    duration: 1000,
    easing: 'ease-out-back',
    delay: 600,
  })
  const imgArr = [
    {
      url: 'images/home/img1.png',
      title: 'SWAP',
      desc: 'Swap tokens',
      delay: 300,
    },
    {
      url: 'images/home/img2.png',
      title: 'POOL',
      desc: 'Create LP and start mining',
      delay: 400,
    },
    {
      url: 'images/home/img3.png',
      title: 'GARDENS',
      desc: 'Add liquidity in the Gardens',
      delay: 500,
    },
    {
      url: 'images/home/img4.png',
      title: 'PLANNER',
      desc: 'Earn staking bonus in the Planner',
      delay: 400,
    },
    {
      url: 'images/home/img5.png',
      title: 'HEROES',
      desc: 'Enlist your heroes to defend the gardens',
      delay: 300,
    },
    {
      url: 'images/home/img6.png',
      title: 'LAND',
      desc: 'Purchase land to build a kingdom of your own',
      delay: 400,
    },
    {
      url: 'images/home/img7.png',
      title: 'BUILDINGS',
      desc: 'Decide how to imporve your kingdom',
      delay: 500,
    },
    {
      url: 'images/home/img8.png',
      title: 'EQUIPMENT',
      desc: 'Craft your own equipment to give your warriors heroic power',
      delay: 300,
    },
  ]
  console.log(AOS, 'aos')

  const [address, setAddress] = useState('')
  const [showMsg, setShowMsg] = useState(false)

  const getWhiteList = async (whitelistAddress: string) => {
   if (whitelist.indexOf(whitelistAddress) !== -1) {
    setShowMsg(true)
   }

  }

  return (
    <StyledContainer id="Home">
      <StyledBg>
        <StyledBgContent>
          <img src="images/logo/rising-1.png" className="logo1" alt="" data-aos="fade-up" />
          {/* <BtnRow data-aos="fade-up" data-aos-delay="300">
            <img src="images/home/play.png" alt="" />
            <a href=" https://beta.songofrising.com/" target="_blank" rel="noreferrer">
              <PlayBtn>{t('PLAY NOW')}</PlayBtn>
            </a>
          </BtnRow> */}
          <span data-aos="fade-up" data-aos-delay="600">
            {t('THE JOURNEY WILL BE HARD,')}
          </span>
          <span data-aos="fade-up" data-aos-delay="900">
            {t('BUT THE RESULT WILL BE VICTORY')}
          </span>
          {new Date(1716552000 * 1000).getTime() - new Date().getTime() > 0 ? (
            <p data-aos="fade-up" data-aos-delay="900">
              {t('Donation will Launch on Solana, Coming soon')}
            </p>
          ) : (
            <p data-aos="fade-up" data-aos-delay="900" style={{ fontSize: 20, color: 'orange' }}>
              {t('SOL Donation Address: G6S29YtZ78J78Rk9djcpyvNPJGAo943gKWRtpNy3irCf')}
            </p>
          )}
          <StyleWhiteList>
            {new Date(1716552000 * 1000).getTime() - new Date().getTime() > 0 ? (
              <Timer
                formatValue={(value) => `${value < 10 ? `0${value}` : value} `}
                initialTime={new Date(1716552000 * 1000).getTime() - new Date().getTime()}
                lastUnit="h"
                direction="backward"
              >
                <TimerWrapper>
                  <li>
                    <h1>
                      <Timer.Hours />
                    </h1>
                    <p>HRS</p>
                  </li>
                  <li>
                    <h1>:</h1>
                    <p>&nbsp;</p>
                  </li>
                  <li>
                    <h1>
                      <Timer.Minutes />
                    </h1>
                    <p>MIN</p>
                  </li>
                  <li>
                    <h1>:</h1>
                    <p>&nbsp;</p>
                  </li>
                  <li>
                    <h1>
                      <Timer.Seconds />
                    </h1>
                    <p>SEC</p>
                  </li>
                </TimerWrapper>
              </Timer>
            ) : (
              <Timer
                formatValue={(value) => `${value < 10 ? `0${value}` : value} `}
                initialTime={new Date(1716638400 * 1000).getTime() - new Date().getTime()}
                lastUnit="h"
                direction="backward"
              >
                <TimerWrapper>
                  <li>
                    <h1>
                      <Timer.Hours />
                    </h1>
                    <p>HRS</p>
                  </li>
                  <li>
                    <h1>:</h1>
                    <p>&nbsp;</p>
                  </li>
                  <li>
                    <h1>
                      <Timer.Minutes />
                    </h1>
                    <p>MIN</p>
                  </li>
                  <li>
                    <h1>:</h1>
                    <p>&nbsp;</p>
                  </li>
                  <li>
                    <h1>
                      <Timer.Seconds />
                    </h1>
                    <p>SEC</p>
                  </li>
                </TimerWrapper>
              </Timer>
            )}

            <h1 className="title">Check your whitelist permissions</h1>
            <p>{address !== '' && (showMsg ? 'You are a whitelist user' : 'You are not a whitelist user')}</p>
            <input
              type="text"
              placeholder="Type your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <LaunchBtn onClick={() => getWhiteList(address)} />
          </StyleWhiteList>
        </StyledBgContent>
      </StyledBg>
      <StyleFeatures>
        <StyledTitle>
          <img src="images/home/left.png" alt="" />
          <span>{t('GAME FEATURES')}</span>
          <img src="images/home/right.png" alt="" />
        </StyledTitle>
        <StyleFeaturesList>
          {imgArr.map((item, index) => {
            return (
              <StyleFeaturesRow key={`${index + 1}FeaturesInfo`} data-aos="fade-down" data-aos-delay={item.delay}>
                <img src={item.url} alt="" />
                <StyleFeaturesInfo>
                  <div className="title">{t(item.title)}</div>
                  {/* <div className='InfoImg'> */}
                  {/*     <img src='images/home/2-4.png' alt=''/> */}
                  {/* </div> */}
                  <div className="desc">{t(item.desc)}</div>
                </StyleFeaturesInfo>
              </StyleFeaturesRow>
            )
          })}
        </StyleFeaturesList>
      </StyleFeatures>
    </StyledContainer>
  )
}

const LaunchBtn = styled.button.attrs({ url: '/images/home/Launch.png' })`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-color: transparent;
  border: none;
  width: 20rem;
  height: 3rem;
  cursor: pointer;
`

const TimerWrapper = styled.div.attrs({ url: '/images/home/mission.png' })`
  // background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  height: 8rem;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  -webkit-text-stroke: 1px #3f1205;
  li {
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    h1 {
      background-image: linear-gradient(to bottom, #faf3ab, #a86410);
      -webkit-background-clip: text;
      color: transparent;
      padding-bottom: 10px;
      font-size: 60px;
      border: none;
    }
    p {
      background-image: linear-gradient(to bottom, #faf3ab, #a86410);
      -webkit-background-clip: text;
      color: transparent;
      border: none;
    }
  }
`

const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.config.nav.heightMb} 0 0;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledBg = styled.div.attrs({ url: '/images/home/1-0.png' })`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 240px;
  //margin-bottom: -2px;
  background-color: ${({ theme }) => theme.expandColor.bg.primary};
  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 100vh;
  }
`
const StyledBgContent = styled.div`
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 200px;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 100vh;
  }
  & .logo1 {
    object-fit: cover;
    height: 70px;
    margin: 20px 0;
    ${({ theme }) => theme.mediaQueries.sm} {
      //margin: -100px 0 0px;
      height: 240px;
    }
  }
  & span {
    font-size: 14px;
    font-family: Aleo;
    //background: linear-gradient(to right, ${({ theme }) => theme.expandColor.color.primary}, ${({ theme }) =>
      theme.expandColor.color.color3});
    -webkit-background-clip: text;
    //color: transparent;
    color: ${({ theme }) => theme.expandColor.color.color5};
    padding: 5px;
    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 36px;
      padding: 10px;
    }
  }
  & p {
    font-size: 30px;
    font-family: Aleo;
    //background: linear-gradient(to right, ${({ theme }) => theme.expandColor.color.primary}, ${({ theme }) =>
      theme.expandColor.color.color3});
    -webkit-background-clip: text;
    color: ${({ theme }) => theme.expandColor.color.color2};
    font-size: 14px;
  }
`
const BtnRow = styled.div`
  height: 50px;
  position: relative;
  //margin: 10px 0;
  margin-bottom: 20px;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 80px 0;
  }
  & img {
    object-fit: cover;
    height: 35px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 50px;
    }
  }
`
const PlayBtn = styled.div`
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  font-family: Aleo;
  text-align: center;
  padding-top: 8px;
  color: ${({ theme }) => theme.expandColor.color.color5};
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 28px;
    padding-top: 13px;
  }
`
const StyleFeatures = styled.div`
  width: 100%;
  padding-bottom: 50px;
  background-color: ${({ theme }) => theme.expandColor.bg.primary};
`
const StyledTitle = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  padding: 50px 0 30px;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 80px 0 50px;
  }
  & span {
    padding: 2px 10px 0;
    font-size: 20px;
    font-family: Aleo;
    background: linear-gradient(to right, #e0c266, #efefa0);
    -webkit-background-clip: text;
    color: transparent;
    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 30px;
    }
  }
  & img {
    width: 75px;
    height: 20px;
    ${({ theme }) => theme.mediaQueries.sm} {
      width: 130px;
      height: 30px;
    }
  }
`

const StyledMission = styled.div`
  margin: 0 auto;
  width: 60%;
  img {
    display: block;
  }
`
const StyleFeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyleFeaturesRow = styled.div`
  //position: relative;
  width: 256px;
  //height: 320px;
  margin: 40px 0 0;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 20px;
  }
`
const StyleFeaturesInfo = styled.div`
  //position: absolute;
  //left: 0;
  //top: -18px;
  //width: 240px;
  //height: 320px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: space-between;
  //text-align: center;
  & .title {
    //width: 240px;
    height: 50px;
    line-height: 50px;
    font-size: 26px;
    color: ${({ theme }) => theme.expandColor.color.white};
  }
  & .InfoImg {
    width: 240px;
    height: 160px;
    & img {
      object-fit: cover;
    }
  }
  & .desc {
    font-size: 20px;
    font-family: Kano;
    color: ${({ theme }) => theme.expandColor.color.white};
  }
`
const StyleWhiteList = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  text-align: center;
  position: relative;
  z-index: 2147483648;
  h1 {
    color: #fff;
    padding: 10px 0 10px 0;
    background: #6d2509;
    border-radius: 10px;
    border: 2px solid #5d210a;
  }
  p {
    font-size: 12px;
    margin-top:10px;
  }
  input {
    border: 2px solid #f3a200c9;
    outline: none;
    margin: 10px 0;
    padding: 10px 10px;
    border-radius: 7px;
    background: transparent;
  }
`

export default Top
