import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import SVG from "react-inlinesvg";
import telegramSvg from "assets/contactIcon/telegram.svg"
import twitterSvg from "assets/contactIcon/twitter.svg"
import facebookSvg from "assets/contactIcon/facebook.svg"
import discordSvg from "assets/contactIcon/discord1.svg"

import { useTranslation } from '../../contexts/Localization'
import MenuButton from './MenuButton'
import ContectRow from "../../views/Home/components/ContectRow";



const Header = () => {
  const { isXl,isSm } = useMatchBreakpoints();
  const isMobile = isXl === false;
  const { t } = useTranslation()
  const [showMenu,setShowMenu]=  useState(false)
  const mobileMenuShow = (status)=> {
    setShowMenu(status)
  }
  const scrollToAnchor = (id)=>{
    document.getElementById(id).scrollIntoView({ behavior: 'smooth',block: 'start' });
  }

  const [isTop, setIsTop]= useState(true);
  useEffect(() => {
    window.onscroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      // console.log('scrollTop', scrollTop)
      const isFixed = scrollTop < 80;
      setIsTop(isFixed);
      // console.log('isTop', isTop)
    }
  }, [isTop])
  const { hash} = useLocation()
  // console.log(hash,'-------hash--------')
  return (
    <FixBox className={`${isTop ? '' : 'slideNav'}`}>
      {/* pc header */}
      <FixHeader className="pc">
        <StyledLeft>

          <HeaderLogo>
            <img alt="" src="/rising-1.png"/>
          </HeaderLogo>

          <PcMenuList>
            <StyledNavLink to={{
              pathname: '/',
              hash: '#Home',
            }} onClick={()=>scrollToAnchor('Home')} className={`${hash==='#Home'?'activeNav':''} pc`}>
              <PcMenuItem>{t('Home')}</PcMenuItem>
            </StyledNavLink>
            <StyledNavLink to={{
              pathname: '/',
              hash: '#RoadMap',
            }} onClick={()=>scrollToAnchor('RoadMap')}  className={`${hash==='#RoadMap'?'activeNav':''} pc`}>
              <PcMenuItem>{t('RoadMap')}</PcMenuItem>
            </StyledNavLink>
            <StyledNavLink to={{
              pathname: '/',
              hash: '#Team',
            }} onClick={()=>scrollToAnchor('Team')}  className={`${hash==='#Team'?'activeNav':''} pc`}>
              <PcMenuItem>{t('Team')}</PcMenuItem>
            </StyledNavLink>
            {/*
             <StyledNavLink to={{
              pathname: '/',
              hash: '#Audit',
            }} className={`${hash==='#Audit'?'activeNav':''} pc`}>
              <PcMenuItem>{t('Audit')}</PcMenuItem>
            </StyledNavLink>
            */}

            {/* <StyledA href='https://docs.songofrising.com/' className={`${hash==='#Docs'?'activeNav':''} pc`}>
              <PcMenuItem>{t('Docs')}</PcMenuItem>
            </StyledA> */}
            {/* <StyledNavLink to={{
              pathname: '/Tutorial',
            }} className="pc">
              <PcMenuItem>{t('Tutorial')}</PcMenuItem>
            </StyledNavLink> */}

          </PcMenuList>
        </StyledLeft>
        <ContectRow/>
        {/*
         <BtnRow>
          <img src='/images/home/connect.png' alt=''/>
          <PlayBtn>
            {t('CONNECT')}
          </PlayBtn>
        </BtnRow>
        */}

      </FixHeader>
      {/* mobile header */}
      <FixHeader className="mb">
        <FixMobileHeader>
          <HeaderLogo>
            <img alt="" src="/rising-1.png"/>
          </HeaderLogo>
          <MenuButton  clickFun={mobileMenuShow}/>
        </FixMobileHeader>

        <MobileMenuList className={showMenu?'show':''}>
          <NavLink to="/#Home" onClick={()=>scrollToAnchor('Home')}>
            <MobileMenuItem>{t('Home')}</MobileMenuItem>
          </NavLink>
          <NavLink to="/#RoadMap" onClick={()=>scrollToAnchor('RoadMap')}>
            <MobileMenuItem>{t('RoadMap')}</MobileMenuItem>
          </NavLink>
          <NavLink to="/#Team" onClick={()=>scrollToAnchor('Team')}>
            <MobileMenuItem>{t('Team')}</MobileMenuItem>
          </NavLink>
          {/*
           <NavLink to="/#Audit">
            <MobileMenuItem>{t('Audit')}</MobileMenuItem>
          </NavLink>
          */}

          {/* <StyledA href="https://docs.songofrising.com/">
            <MobileMenuItem style={{width:'100%'}}>{t('Docs')}</MobileMenuItem>
          </StyledA> */}
          {/* <NavLink to="/Tutorial">
            <MobileMenuItem>{t('Tutorial')}</MobileMenuItem>
          </NavLink> */}

          <ContectRow/>
          {/*
          <BtnRow>
            <img src='images/home/connect.png' alt=''/>
            <PlayBtn>
              {t('CONNECT')}
            </PlayBtn>
          </BtnRow>
          */}
        </MobileMenuList>
      </FixHeader>
    </FixBox>
  )
}

const FixBox = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${({theme})=> theme.expandColor.color.black};
  //background-color: ${({theme})=> theme.expandColor.bg.bg10};
  ${({theme})=>theme.mediaQueries.sm}  {
    position: fixed;
    //position: sticky;
    background-color: ${({theme})=> theme.expandColor.bg.bg10};
  };
  display: flex;
  justify-content: center;
  &.slideNav {
    //background-color: ${({theme})=> theme.expandColor.color.color1};
    background-color: ${({theme})=> theme.expandColor.bg.bg10};
    border-bottom: 1px solid ${({theme})=> theme.expandColor.color.primary};
  }
`

const FixHeader = styled.div`
  display: flex;
  max-width: 1200px;
  //margin: auto;
  box-sizing: border-box;
  padding: 0 15px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &.pc {
    display: none;
  }
  &.mb {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 46px;
    width: 100%;
  }
  ${({theme})=>theme.mediaQueries.sm}  {
    height: 64px;
    max-width: 90%;
    &.pc {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &.mb {
      display: none;
    }
  }
`
const StyledLeft = styled.div`
  display: flex;
  height: 100%;
`
const HeaderLogo = styled.div`
  width: auto;
  height: 46px;
  box-sizing: border-box;
  padding: 5px 0;
  ${({theme})=>theme.mediaQueries.sm}  {
    height: 64px;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const PcMenuList = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 50px;
`
const PcMenuItem = styled.div`
  font-size: 20px;
  font-family: Kano;
  position: relative;
`
const StyledNavLink = styled(NavLink)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({theme})=> theme.expandColor.color.color2};
  &:hover{
    color: ${({theme})=> theme.expandColor.color.color5};
  }
  &.pc {
    margin: 0 20px;
  }
  &.activeNav {
    color: ${({theme})=> theme.expandColor.color.color5};
  }
`
const StyledA = styled.a`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({theme})=> theme.expandColor.color.color2};
  &:hover{
    color: ${({theme})=> theme.expandColor.color.color5};
  }
  &.pc {
    margin: 0 20px;
  }
  &.activeNav {
    color: ${({theme})=> theme.expandColor.color.color5};
  }
`
const MobileMenuList = styled.nav`
  width:  100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all .3s ease-in-out 0s;
  max-height: 0px;
  &.show {
    max-height: 500px;
  }
`

const FixMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const MobileMenuItem = styled.div`
  font-size: 18px;
  font-family: Kano;
  line-height: 1.5;
  padding: 10px;
  text-align: center;
  color: ${({theme})=>theme.expandColor.color.color2};
  &.active {
    color: ${({theme})=>theme.expandColor.color.primary};
  }
`

const BtnRow = styled.div`
  width: 126px;
  height: 33px;
  position: relative;
  
  ${({theme})=>theme.mediaQueries.sm}  {
    margin-left: 100px;
    margin-top: 0;
  }
  & img {
    width: 100%;
    height: 100%;
  }
`
const PlayBtn= styled.div`
  width: 126px;
  height: 33px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  font-family: Kano;
  text-align: center;
  line-height: 33px;
  
`
export default Header
