import React, { lazy, useEffect } from 'react'
import { Router,HashRouter,BrowserRouter, Redirect, Route, Switch,useLocation,withRouter } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import 'antd/dist/antd.css';
import GlobalStyle from './style/Global' 
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import Home from "./views/Home";
import Tutorial from "./views/Tutorial";
import Tour from "./views/Tour";

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))

const App: React.FC = () => {
  return (
    <BrowserRouter >
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
             <Route path="/Tutorial" exact>
                <Tutorial />
             </Route>
             <Route path="/Tour" exact>
                <Tour />
             </Route>
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      <ToastListener />
    </BrowserRouter >
  )
}

export default React.memo(App)
