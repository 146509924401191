import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import  AOS from 'aos'
import ContectRow from "./ContectRow";

const Footer: React.FC = () => {
  const { t } = useTranslation()
    AOS.init({
        duration:1000,
        easing:'ease-out-back',
        delay:600

    })
    const scrollToAnchor = (id)=>{
        document.getElementById(id).scrollIntoView({ behavior: 'smooth',block: 'start' });
    }
    const { hash} = useLocation()
  return (
    <StyledContainer>
        <StyledDiv/>
        <StyleFooter>
            <StyledBg>
                <img src='images/logo/rising-3.png' alt='' data-aos="fade-up"/>
            </StyledBg>
            <PcMenuList>
                <StyledNavLink to={{
                    pathname: '/',
                    hash: '#Top',
                }} onClick={()=>scrollToAnchor('Top')} className={`${hash==='#Top'?'activeNav':''}`} >
                    <PcMenuItem>{t('Home')}</PcMenuItem>
                </StyledNavLink>
                <PcMenuItem>|</PcMenuItem>
                <StyledNavLink to={{
                    pathname: '/',
                    hash: '#RoadMap',
                }} onClick={()=>scrollToAnchor('RoadMap')}  className={`${hash==='#RoadMap'?'activeNav':''}`}>
                    <PcMenuItem>{t('RoadMap')}</PcMenuItem>
                </StyledNavLink>
                <PcMenuItem>|</PcMenuItem>
                <StyledNavLink to={{
                    pathname: '/',
                    hash: '#Team',
                }} onClick={()=>scrollToAnchor('Team')}  className={`${hash==='#Team'?'activeNav':''}`}>
                    <PcMenuItem>{t('Team')}</PcMenuItem>
                </StyledNavLink>
                {/* <PcMenuItem>|</PcMenuItem> */}
                {/*
                <StyledNavLink to={{
                    pathname: '/',
                    hash: '#Audit',
                }} className={`${hash==='#Audit'?'activeNav':''}`}>
                    <PcMenuItem>{t('Audit')}</PcMenuItem>
                </StyledNavLink>
                <PcMenuItem>|</PcMenuItem>
                 */}
                {/* <StyledNavLink to={{
                    pathname: '/',
                    hash: '#Docs',
                }} className={`${hash==='#Docs'?'activeNav':''}`}>
                    <PcMenuItem>{t('Docs')}</PcMenuItem>
                </StyledNavLink> */}
                {/* <PcMenuItem>|</PcMenuItem>
                <StyledNavLink to={{
                    pathname: '/Tutorial',
                }}>
                    <PcMenuItem>{t('Tutorial')}</PcMenuItem>
                </StyledNavLink> */}
            </PcMenuList>
         <ContectRow/>
            <CopyrightRow>
                <CopyrightItem>
                    {t('© Copyright 2022 • Song of rising • All rights reserved')}
                </CopyrightItem>
                <CopyrightItem>
                    {t('License Agreement')}
                </CopyrightItem>
            </CopyrightRow>
        </StyleFooter>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-top: -300px;
  display: flex;
  justify-content: center;
`
const StyledDiv = styled.div`
  width: 200%;
  height: 400px;
  position: absolute;
  left: -50%;
  top: 0;
  //background-color: ${({theme})=> theme.expandColor.bg.footBg};
  background: linear-gradient(180deg, ${({theme})=>theme.expandColor.bg.footBg}, ${({theme})=>theme.expandColor.color.black});
  border-radius: 50% 50% 0 0;
  ${({theme})=>theme.mediaQueries.sm} {
    width: 120%;
    left: -10%;
  }
`
const StyleFooter = styled.div`
  width: 100%;
  padding: 50px;
  position: absolute;
  top: 0;
  left: 0;
`
const StyledBg = styled.div`
  width: 100%;
  text-align: center;
  & img{
    object-fit: cover;
    width: 246px;
  }
`
const PcMenuList = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
`
const PcMenuItem = styled.div`
  font-size: 18px;
  font-family: Kano;
  color: ${({theme})=> theme.expandColor.color.color2};
  position: relative;
  margin: 0;
  ${({theme})=>theme.mediaQueries.sm} {
    margin: 0 10px;
  }
`
const StyledNavLink = styled(NavLink)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
 
  &:hover {
    &:after {
      width: 100%;
      margin: auto;
      transition: width 0.3s;
    }
  }
  &.activeNav {
    &:after {
      width: 100%;
    }
  }
`
const ContactsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  ${({theme})=>theme.mediaQueries.sm}  {
    //margin-left: 100px;
  }
`
const ContactItem = styled.div`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    & img {
      position: absolute;
      left: -30px;
      filter: drop-shadow(30px 0px ${({theme})=>theme.expandColor.color.color5});
    }
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const CopyrightRow = styled.div`
  width: 100%;
  margin-top: 50px;
`
const CopyrightItem = styled.div`
  width: 100%;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  font-family: Kano;
  color: ${({theme})=> theme.expandColor.color.color2};
  
`
export default Footer
