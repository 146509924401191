import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"
import { GameBtnTheme,Back} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void
}
const Welcome: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();

    const DialogRender =(
        <StyledWelcome>
            <StyledWelcomeRow>
                <StyledWelcomeLeft>
                    <img src='images/npc/go-B.png' alt=''/>
                </StyledWelcomeLeft>
                <StyledWelcomeRight>
                    <span>
                        {t("Now that you've arrived in Song Of Rising, there's a lot do!")}
                    </span>
                    <span>
                        {t("It can be overwhelming at first, so let me show you around a bit. I don't know how much experience you have with cryptocurrencies, decentralized exchanges, or NFTs. For the purposes of this tour, I'm going to keep things short and sweet.")}
                    </span>
                    <span>
                        {t("First, I'm going to give you some pointers about the interface.")}
                    </span>

                </StyledWelcomeRight>
                <StyledWelcomeBtn onClick={()=>{setLable('map1')}} ><span>{t("LET'S GO!")}</span></StyledWelcomeBtn>
            </StyledWelcomeRow>

        </StyledWelcome>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("Welcome!")}/>
    }

const StyledWelcome = styled.div`
  width: 100%;
  padding: 30px 30px 0 20px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 350px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
  & .logo{
    width: 100px;
    
  }
`

const StyledWelcomeRow = styled.div`
  // display: flex;
  // flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledWelcomeLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & img {
    object-fit: cover;
    //height: 120px;
    width: 100%;
  }
`

const StyledWelcomeRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`
const StyledWelcomeBtn = styled(GameBtnTheme)`
  width: 200px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 30px;
  //color: ${({theme})=> theme.expandColor.color.primary};
  //background-color: ${({theme})=> theme.expandColor.color.active};
  
  cursor: pointer;
  margin: 30px 0 0 0;
  float: right;
`
export default Welcome
