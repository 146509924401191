import React, {useState} from 'react'
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Marketplace1: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren = (<><span>
                        {t("Welcome to the Marketplace, adventurer!")}
                    </span>
        <span>
                        {t("This is the heart of the bustling Song Of Rising economy. Here, new players should speak to the ")}
            <b>{t("Trader, Merchant Matoya")}</b>
            {t(". She will help you trade your OKT tokens for RUBY, the currency of Song Of Rising.")}
                    </span>
        <span>
                        {t("Whether you’re here to grow your RUBY balance through investment or to use your RUBY to acquire Heroes and take on all of the challenges of Song Of Rising, you’ll need to talk to Matoya to get started.")}
                    </span>
        <span>
                        {t("Matoya doesn’t just trade OKT and RUBY, though. She functions as the face of our decentralized exchange, allowing players to ")}
            <b>{t("swap tokens")}</b>
            {t(" on the OKX Chain blockchain with extremely low fees and transaction times of only a few seconds.")}
                    </span>
        <span>
                        {t("Exchanging tokens is a key part of some in-game activities, so you may visit Matoya quite often.")}
                    </span></>)
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="map3" activeIndex={1} count={2} nextLable="marketplace2"/>
    )
    return <TourDialog setLable={setLable} title={t("Marketplace")} imgUrl='images/npc/go-D.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

export default Marketplace1
