import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

const RoadMap: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledContainer>
        <StyleRoadMap  id="RoadMap">
            <StyledTitle>
                <img src='images/home/left.png' alt=''/>
                <span>{t('ROAD MAP')}</span>
                <img src='images/home/right.png' alt=''/>
            </StyledTitle>
            <StyledBg>
                <img src='images/home/road-map1.png' alt=''/>
            </StyledBg>
        </StyleRoadMap>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`
const StyleRoadMap = styled.div`
  width: 100%;
  padding-bottom: 30px;
  background-color: ${({theme})=>theme.expandColor.bg.primary};
  position: relative;
`
const StyledTitle = styled.div`
  position: absolute;
  width: 100%;
  height: 30px;
  display: flex;
  //padding: 50px 0 30px;
  justify-content: center;
  align-items: center;
  top: 15px;
  ${({theme})=>theme.mediaQueries.sm} {
    padding: 80px 0 50px;
    top: 5%;
  }
  & span {
    padding: 2px 10px 0;
    font-size: 20px;
    font-family: Aleo;
    background: linear-gradient(to right, #e0c266, #efefa0);
    -webkit-background-clip: text;
    color: transparent;
    ${({theme})=>theme.mediaQueries.sm} {
      font-size: 30px;
    }
  }
  & img {
    width: 75px;
    height: 20px;
    ${({theme})=>theme.mediaQueries.sm} {
      width: 130px;
      height: 30px;
    }
  }
`
const StyledBg = styled.div`
  width: 100%;
  min-height: 200px;
  ${({theme})=>theme.mediaQueries.sm} {
    min-height: 600px;
  }
  & img{
    object-fit: cover;
    width: 100%;
    height:100%;
    min-height: 200px;
    ${({theme})=>theme.mediaQueries.sm} {
      min-height: 600px;
    }
  }
`
export default RoadMap
