import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import  AOS from 'aos'

const Team: React.FC = () => {
  const { t } = useTranslation()
    AOS.init({
        duration:1000,
        easing:'ease-out-back',
        delay:300

    })
    const imgArr = [{
      url:'images/home/npc1.png',
        title:'Freddy',
        desc:'CEO'
    },{
        url:'images/home/npc2.png',
        title:'LEO',
        desc:'CMO'
    },{
        url:'images/home/npc3.png',
        title:'Eric',
        desc:'CTO'
    },{
        url:'images/home/npc4.png',
        title:'Barry',
        desc:'CFO'
    },{
        url:'images/home/npc5.png',
        title:'Gabriel',
        desc:'Front-End Director'
    },{
        url:'images/home/npc6.png',
        title:'Wenbin',
        desc:'Project Manager'
    },{
        url:'images/home/npc7.png',
        title:'Rita',
        desc:'Social Media Manager'
    },{
        url:'images/home/npc8.png',
        title:'Mucy',
        desc:'Piexel Artist'
    }]
  return (
    <StyledContainer id="Team">
        <StyleTeam>
            <StyledTitle>
                <img src='images/home/left.png' alt=''/>
                <span>{t('TEAM')}</span>
                <img src='images/home/right.png' alt=''/>
            </StyledTitle>
            <StyleTeamList>
                {imgArr.map((item,index)=>{
                    return (
                        <StyleTeamRow key={`${index+1}TeamList`}>
                            <img src={item.url} alt='' data-aos="flip-down"/>
                            <StyleTeamInfo data-aos="fade-down">
                                {/* <img src='images/home/3-1.png' alt=''/> */}
                                <div className='title'>{t(item.title)}</div>
                                <div className='desc'>{t(item.desc)}</div>
                            </StyleTeamInfo>
                        </StyleTeamRow>
                    )
                })}

            </StyleTeamList>
        </StyleTeam>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`
const StyleTeam = styled.div`
  width: 100%;
  padding-bottom: 400px;
  background-color: ${({theme})=>theme.expandColor.bg.primary};
`
const StyledTitle = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  padding: 50px 0 30px;
  justify-content: center;
  align-items: center;
  ${({theme})=>theme.mediaQueries.sm} {
    padding: 80px 0 50px;
  }
  & span {
    padding: 2px 10px 0;
    font-size: 20px;
    font-family: Aleo;
    background: linear-gradient(to right, #e0c266, #efefa0);
    -webkit-background-clip: text;
    color: transparent;
    ${({theme})=>theme.mediaQueries.sm} {
      font-size: 30px;
    }
  }
  & img {
    width: 75px;
    height: 20px;
    ${({theme})=>theme.mediaQueries.sm} {
      width: 130px;
      height: 30px;
    }
  }
`
const StyleTeamList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  ${({theme})=>theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const StyleTeamRow = styled.div`
  //position: relative;
  width: 230px;
  height: 350px;
  margin:40px 0 0;
  ${({theme})=>theme.mediaQueries.sm} {
    margin:0 20px;
  }
`
const StyleTeamInfo = styled.div`
 text-align: center;
  & img {
    width: 230px;
    object-fit: cover
  }
  & .title {
    //width: 230px;
    font-size: 28px;
    font-family: Aleo;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(to right, #e0c266, #efefa0);
    -webkit-background-clip: text;
    color: transparent;
  }
  & .desc {
    width: 230px;
    font-size: 20px;
    font-family: Kano;
    color: ${({theme})=>theme.expandColor.color.white};
  }
`
export default Team
