import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from "../../contexts/Localization";
import {Back, GameBtnTheme, Next} from "../../style/UiStyle"

interface Props {
    setLable: (event) => void
    activeIndex:number
    count:number
    backLable:string
    nextLable:string
    isNextMap?:boolean
    nextTitle?:string
}
const BackOrNext: React.FC<Props> = ({setLable,activeIndex,count,backLable,nextLable,isNextMap=false,nextTitle}) => {
    const { t } = useTranslation();
    const divBox = []
    for (let i =0 ;i<count;i++){
        divBox.push(i)
    }
    return <StyledMapBotton>
        <StyledMapBack onClick={()=>setLable(backLable)}>
            <img src="/images/default/btn/3-4.png" alt="" className="left"/>
            <span>{t("Back")}</span>
        </StyledMapBack>
        <StyledMapPage>
            <span>{activeIndex}/{count}</span>
            <StyledMapPageDiv>
                {divBox.map((item)=>{
                    return (item+1===activeIndex?<div className='show' key={`${item}divBox`}/>:<div key={`${item}divBox`} className='hide'/>)
                })}

            </StyledMapPageDiv>
        </StyledMapPage>
        {
            isNextMap?  <StyledMapNext onClick={()=>{setLable(nextLable)}} $isNextMap={isNextMap}>
                <span>{nextTitle}</span>

            </StyledMapNext>:  <StyledMapNext onClick={()=>{setLable(nextLable)}} $isNextMap={isNextMap}>
                 <span>{t("Next")}</span>
                        <img src="/images/default/btn/4-4.png" alt="" className="left"/>

            </StyledMapNext>
        }
    </StyledMapBotton>
}


const StyledMapBotton = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`
const StyledMapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledMapPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMapBack = styled(Back)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  //background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-right: 10px;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledMapNext = styled(Next)<{$isNextMap:boolean}>`
  min-width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 0;
  padding-left: 10px;
  //background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: ${({$isNextMap})=>$isNextMap?'0 1px 0 0':'0 10px 0 0'};
      transition: all 0.5s;
    }
  }
`

export default BackOrNext