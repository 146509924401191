import React, {useState} from 'react'
import {Link} from "react-router-dom";
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"
import { GameBtnTheme,Back} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void
}
const Playing: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender =(
        <StyledPlaying>
            <StyledPlayingRow>
                <StyledPlayingLeft>
                    <img src='images/npc/go-A.png' alt=''/>
                </StyledPlayingLeft>
                <StyledPlayingRight>
                    <span>
                        {t("No problem! You can visit those tutorials at any time, from the homepage of our website. I strongly enourage you to join our Discord and follow our socials.")}
                    </span>
                    <span>
                        {t("To learn more about the game, you can read our white papers at ")}
                        {/* <a href="https://docs.defikingdoms.com" target="_blank" rel="noreferrer">{t("docs.defikingdoms.com.")}</a> */}
                    </span>
                    <span>
                        {t("Thank you for accompanying me today and enjoy your stay in Song Of Rising!")}
                    </span>
                </StyledPlayingRight>
            </StyledPlayingRow>
            <StyledPlayingBotton>
                <StyledPlayingBtn>
                 <span> <a href="https://beta.songofrising.com/" target="_blank" rel="noreferrer">{t("Start Playing")}</a></span>
                </StyledPlayingBtn>
            </StyledPlayingBotton>
        </StyledPlaying>

    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("Enjoy your stay in Song of Rising")}/>
    }

const StyledPlaying = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 380px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledPlayingRow = styled.div`
  // display: flex;
  // flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledPlayingLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & img {
    object-fit: cover;
    width: 100%;
  }
`

const StyledPlayingRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
  & a {
    text-decoration: underline;
    cursor: pointer;
    color: ${({theme})=> theme.expandColor.color.active};
  }
`
const StyledPlayingBotton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`

const StyledPlayingBtn = styled(Back)`
  width: 200px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 30px;
  color: ${({theme})=> theme.expandColor.color.color10};
  //background-color: ${({theme})=> theme.expandColor.color.active};
  margin-top: 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  & a {
    & :hover{
      color: ${({theme})=> theme.expandColor.color.color10};
    }
  }
`
export default Playing
