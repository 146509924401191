import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Professions: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren = (<>
         <span>
                        {t("Ain’t this a geographical oddity? Over there is the entrance to the mine, situated right next to a suitable garden plot, a fishing pond, and a forest area for foraging! Once you have a Hero, this is the place to visit to take advantage of his or her skills.")}
                    </span>
        <span>
                        {t("Each of the available professions — ")}
            <b>{t("mining, fishing, gardening,")}</b>
            {t(" and ")}
            <b>{t("foraging")}</b>
            {t(" — generates rewards for you when you put your Heroes to work. The NPCs here specialize in quests for novices in their respective professions, so once you have a Hero you should bring them here to start gaining Experience and resources. Each quest has a Stamina cost.")}
                    </span>
    </>)

    // pioneer
    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="tavern2" activeIndex={1} count={1} nextLable="portal" isNextMap nextTitle="The Church"/>
    )
    return <TourDialog setLable={setLable} title={t("The Pioneer")} imgUrl='images/npc/go-H.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledProfessions = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 400px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledProfessionsRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledProfessionsLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledProfessionsRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledProfessionsBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledProfessionsBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledProfessionsNext = styled.div`
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-top: 0;
  }
  & span {
    padding:0 1px 0 0;
  }
`
export default Professions
