import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import TourDialog from  '../../../components/TourDialog'

interface Props {
    setLable: (event) => void
}
const Castle: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const textChildren = (<>
         <span>
                        {t("Also, let's take a quick detour over to the Castle.")}
                    </span>
        <span>
                        {t("Here you can see a roster of all the lands. If you are in need of land, you can buy what's for sale here. If you have a land, you can choose to sell it. There will be many more features here in the future.")}
                    </span>
    </>)

    const btnChildren = (
        <BackOrNext setLable={setLable} backLable="tavern1" activeIndex={1} count={1} nextLable="portal" isNextMap nextTitle="Castle"/>
    )
    return <TourDialog setLable={setLable} title={t("The Pioneer")} imgUrl='images/npc/go-F.png' btnChildren={btnChildren} textChildren={textChildren}/>
    }

const StyledCastle = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    height: 360px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledCastleRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledCastleLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 180px;
  }
`

const StyledCastleRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledCastleBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledCastleBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledCastleNext = styled.div`
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-top: 0;
  }
  & span {
    padding:0 1px 0 0;
  }
`
export default Castle
