import React, {useState} from 'react'
import styled from 'styled-components'
import NpcModalBoxBgUi,{Title} from "../../style/UiStyle"
import {useTranslation} from "../../contexts/Localization";

interface Props {
    setLable?: (event) => void
    title?:string
    isLogo?:boolean,
    DialogChildren?:React.ReactNode
    h?:string
}

const Dialog: React.FC<Props> = ({setLable,title,isLogo=false,DialogChildren,h}) => {
    const { t } = useTranslation();
    return (
      <StyledWelcome>
          <StyleTop $isLogo={isLogo}>
              {
                  isLogo? <img src="images/logo/rising.png" alt="" className="logo"/>: <StyleTitile h={h}><span>{title}</span></StyleTitile>
              }
          </StyleTop>

          {DialogChildren}
          </StyledWelcome>

)
}


const StyledWelcome = styled(NpcModalBoxBgUi)`
 position: relative;
  & .logo{
    width: 100px;
    //margin-top: -40px;
  }
  ${({theme}) => theme.mediaQueries.sm} {
    & .logo{
      width: 200px;
      //margin-top: -40px;
    }
  }
 
`
const StyleTitile = styled(Title)`
    `
const StyleTop = styled.div<{$isLogo:boolean}>`
  width:  calc(100% - 70px);
position: absolute;
  top: ${({$isLogo})=>$isLogo?'-10px':'23px'};
  ${({theme}) => theme.mediaQueries.sm} {
    top: ${({$isLogo})=>$isLogo?'-30px':'23px'};
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
 
  
`

export default Dialog