import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import Dialog from "../../../components/Dialog";

interface Props {
    setLable: (event) => void
}
const Metamask2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender =(
        <StyledMetamask>
            <StyledMetamaskRow>
                <StyledMetamaskLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledMetamaskLeft>
                <StyledMetamaskRight>
                    <span>{t("Good! Now that the Metamask extension is installed, we can configure it for Song Of Rising.")}</span>
                    <span>{t("Now, this process takes a few minutes, but don’t worry about me. I’ll be waiting patiently for you to return.")}</span>
                </StyledMetamaskRight>
            </StyledMetamaskRow>

            <StyledMetamaskContent>
                <span>
                    {t("Your wallet information is ")}
                    <b>{t("extremely important and very sensitive.")}</b>
                    {t(" If you lose your seed phrase, no one can recover it for you which means that your account, along with your tokens and items, will be lost forever.")}
                </span>
                <span>
                    {t("Please take the time to create a strong password and to securely store your recovery information.")}
                </span>
                <span>
                    {t("Now, go ahead and click ")}
                    <b>{t("Get Started.")}</b>
                    {t(" If you had Metamask before, you can select Import Wallet, but chances are you’re new to this, so you’ll want to Create a Wallet. Follow the process to get your wallet up and running and come tell me when you’re done.")}
                </span>

            </StyledMetamaskContent>
            <BackOrNext setLable={setLable} backLable="metamask1" activeIndex={2} count={3} nextLable="metamask3" />

        </StyledMetamask>
    )
    return <Dialog  DialogChildren={DialogRender} title={t("Metamask")}/>
    }

const StyledMetamask = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color1};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 500px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMetamaskRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMetamaskLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledMetamaskRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 500px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledMetamaskContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledMetamaskBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMetamaskPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`
const StyledMetamaskPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMetamaskAlready = styled.div`
  width: 240px;
`
const StyledMetamaskChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
     justify-content: end;
  }
`
const StyledMetamaskBack = styled.div`
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledMetamaskNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 10px;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Metamask2
