import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'

import Welcome from "./Welcome";
import Map1 from "./Map1";
import Map2 from "./Map2";
import Map3 from "./Map3";
import Marketplace1 from "./Marketplace1";
import Marketplace2 from "./Marketplace2";
import Gardens1 from "./Gardens1";
import Gardens2 from "./Gardens2";
import Gardens3 from "./Gardens3";
import Jeweler from "./Jeweler";
import Tavern1 from "./Tavern1";
import Tavern2 from "./Tavern2";
import Professions from "./Professions";
import Castle from "./Castle";
import Portal from "./Portal";
import Joining from "./Joining";


const Go: React.FC = () => {
    const { t } = useTranslation()
    const [lable, setLable]= useState('welcome');
    console.log('lable', lable)
    return (
        <StyledGO>
            <StyledGO1 className={`${lable === 'welcome' ? 'show':'hide'}`}>
                <Welcome setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'map1' ? 'show':'hide'}`}>
                <Map1 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'map2' ? 'show':'hide'}`}>
                <Map2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'map3' ? 'show':'hide'}`}>
                <Map3 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'marketplace1' ? 'show':'hide'}`}>
                <Marketplace1 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'marketplace2' ? 'show':'hide'}`}>
                <Marketplace2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'gardens1' ? 'show':'hide'}`}>
                <Gardens1 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'gardens2' ? 'show':'hide'}`}>
                <Gardens2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'gardens3' ? 'show':'hide'}`}>
                <Gardens3 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'jeweler' ? 'show':'hide'}`}>
                <Jeweler setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'tavern1' ? 'show':'hide'}`}>
                <Tavern1 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'tavern2' ? 'show':'hide'}`}>
                <Tavern2 setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'professions' ? 'show':'hide'}`}>
                <Professions setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'castle' ? 'show':'hide'}`}>
                <Castle setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'portal' ? 'show':'hide'}`}>
                <Portal setLable={setLable}/>
            </StyledGO1>
            <StyledGO1 className={`${lable === 'joining' ? 'show':'hide'}`}>
                <Joining setLable={setLable}/>
            </StyledGO1>
        </StyledGO>
        )
    }
const StyledGO = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
`
const StyledGO1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  ${({theme}) => theme.mediaQueries.sm} {
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
  }
  &.show {
    z-index: 5;
    visibility: visible;
    opacity: 1;
    transition: all .5s ease;
    ${({theme}) => theme.mediaQueries.sm} {
      top: 50%;
    }
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
`


export default Go
