import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from  "../../../components/Dialog"

import {Back, GameBtnTheme, Next} from "../../../style/UiStyle"

interface Props {
    setLable: (event) => void,
    setBrowsers: (event) => void,
    setMetamask: (event) => void,
    setHarmony: (event) => void,
    knowYouNext: (event) => void,
    browsers:string
    metamask:string
    harmony:string
}
const KnowYou: React.FC<Props> = ({setLable, setBrowsers, setMetamask, setHarmony, knowYouNext,browsers,metamask,harmony}) => {
    const { t } = useTranslation()
    const changeBrowsers = (event): void =>{
        setBrowsers(event.target.value)
    }
    const changeMetamask = (event): void =>{
        setMetamask(event.target.value)
    }
    const changeHarmony = (event): void =>{
        setHarmony(event.target.value)
    }
    const DialogRender =(
        <StyledKnowYou>
            <span className='content'>
                {t("To get started with the song of rising, you need a few things in order to enter the song of rising. A ")}
                <b>{t("browser")}</b>
                {t(" (Chrome preferred) with a ")}
                <b>{t("Metamask")}</b>
                {t(" wallet extension installed and ")}
                <b>{t("OKX Chain OKT")}</b>
                {t(" tokens in it.")}
            </span>
            <span className='content'>{t("Let me know what you need help setting up!")}</span>
            <StyledKnowYouRow>
                <StyledKnowLeft>
                    <img src='images/npc/1.png' alt=''/>
                </StyledKnowLeft>
                <StyledKnowYouRight>
                    <StyledKnowYouCard>
                        {/*
                         <StyledKnowYouImg>
                            <img src='images/home/1-4.png' alt=''/>
                            <img src='images/home/1-5.png' alt=''/>
                            <img src='images/home/1-6.png' alt=''/>
                            <img src='images/home/1-7.png' alt=''/>
                        </StyledKnowYouImg>
                        */}

                        <StyledKnowYouSelect>
                            <StyleRadioBox onClick={()=>{
                                setBrowsers('1')
                            }}>
                                {browsers==='1'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I use one of these browsers")}</StyleLable>
                            </StyleRadioBox>
                            <StyleRadioBox onClick={()=>{
                                setBrowsers('2')
                            }}>
                                {browsers==='2'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I don't use these browsers")}</StyleLable>
                            </StyleRadioBox>

                        </StyledKnowYouSelect>
                    </StyledKnowYouCard>
                    <StyledKnowYouCard>
                        {/*
                        <StyledKnowYouImg>
                            <img src='images/home/1-4.png' alt=''/>
                        </StyledKnowYouImg>
                        */}

                        <StyledKnowYouSelect>
                            <StyleRadioBox onClick={()=>{
                                setMetamask('1')
                            }}>
                                {metamask==='1'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I have Metamask")}</StyleLable>
                            </StyleRadioBox>
                            <StyleRadioBox onClick={()=>{
                                setMetamask('2')
                            }}>
                                {metamask==='2'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I don't have Metamask")}</StyleLable>
                            </StyleRadioBox>
                            </StyledKnowYouSelect>
                    </StyledKnowYouCard>
                    <StyledKnowYouCard>
                        {/*
                         <StyledKnowYouImg>
                            <img src='images/home/1-4.png' alt=''/>
                        </StyledKnowYouImg>
                        */}

                        <StyledKnowYouSelect>
                            <StyleRadioBox onClick={()=>{
                                setHarmony('1')
                            }}>
                                {harmony==='1'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I have some OKX Chain OKT")}</StyleLable>
                            </StyleRadioBox>
                            <StyleRadioBox onClick={()=>{
                                setHarmony('2')
                            }}>
                                {harmony==='2'?<img src="/images/default/btn/6-1.png" alt="" className="gou"/>:<StyleRadio/>}
                                <StyleLable>{t("I don't have any OKX Chain OKT")}</StyleLable>
                            </StyleRadioBox>

                        </StyledKnowYouSelect>
                    </StyledKnowYouCard>
                </StyledKnowYouRight>
            </StyledKnowYouRow>
            <StyledKnowYouAlready onClick={()=>{setLable('congratulations')}}>
                <span>{t("I have all of these already.")}</span>
            </StyledKnowYouAlready>
            <StyledKnowYouBotton>

                <StyledKnowYouChoose>
                    <StyledKnowYouBack onClick={()=>{setLable('GO')}}>
                        <img src="/images/default/btn/3-4.png" alt="" className="left"/>
                        <span>{t("Back")}</span>
                    </StyledKnowYouBack>
                    <StyledKnowYouNext onClick={knowYouNext}>
                        <span>{t("Next")}</span>
                        <img src="/images/default/btn/4-4.png" alt="" className="left"/>
                    </StyledKnowYouNext>
                </StyledKnowYouChoose>
            </StyledKnowYouBotton>
        </StyledKnowYou>

    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("Getting to Know You")}/>
    }


const StyledKnowYou = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 520px;
  }
  & span {
    padding: 10px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
  }
  & .content {
    font-size: 16px;
  }
`
const StyledKnowYouRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledKnowLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
`
const StyledKnowYouRight = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledKnowYouCard = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledKnowYouImg = styled.div`
  display: flex;
  & img {
    padding: 5px;
  }
`
const StyledKnowYouSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  & div{
   
  }
`
const StyledKnowYouBotton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledKnowYouAlready = styled.div`
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledKnowYouChoose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-top: 0;
  }
`
const StyledKnowYouBack = styled(Back)`
  color: ${({theme})=> theme.expandColor.color.color10};
  //text-decoration: underline;
  cursor: pointer;
  width: 100px;
  & .left{
    width: 20px;
    z-index: 1;
  }
  &:hover {
    
  }
`
const StyledKnowYouNext = styled(Next)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  padding-left: 10px;
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
const StyleRadioBox = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-content: center;
  cursor: pointer;
  & .gou{
    width: 15px;
    height: 15px;
    border: 1px solid #daa36b;
    border-radius: 50%;
  }
`
const StyleRadio = styled.div`
    width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #daa36b;
  
`
const  StyleLable = styled.div`
    margin-left: 5px;
`
export default KnowYou
