import styled from "styled-components";

const NpcModalBoxBgUi = styled.div.attrs({
    url1:"/images/default/border/border-1-t.png",
    url2:"/images/default/border/border-1-r.png",
    url3:"/images/default/border/border-1-b.png",
    url4:"/images/default/border/border-1-l.png",
    url5:"/images/default/border/border-1-tl.png",
    url6:"/images/default/border/border-1-tr.png",
    url7:"/images/default/border/border-1-bl.png",
    url8:"/images/default/border/border-1-br.png",
})`
  position: relative;
  padding: 70px 30px 50px;
  //background-color: #ffebc4;
  background-color:#FFD79C;
  // background-image: url(${props=>props.url1}),url(${props=>props.url2}),url(${props=>props.url3}),url(${props=>props.url4});
  // background-position: 0 0,100% 0,0 100%,0 0;
  // background-repeat: repeat-x,repeat-y,repeat-x,repeat-y;
  // border-radius: 6px;
  z-index: 0;
  border-radius: 6px;
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    display: block;
    image-rendering: pixelated;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props=>props.url1}),url(${props=>props.url2}),url(${props=>props.url3}),url(${props=>props.url4});
    background-position: 0 0,100% 0,0 100%,0 0;
    background-repeat: repeat-x,repeat-y,repeat-x,repeat-y;
    border-radius: 20px;
    z-index: -1;
  }
  
  &:before{
    content: "";
    position: absolute;
    pointer-events: none;
    display: block;
    image-rendering: pixelated;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:  url(${props=>props.url5}),url(${props=>props.url6}),url(${props=>props.url7}),url(${props=>props.url8});
    background-position: 0 0,100% 0,0 100%,100% 100%;
    background-repeat: no-repeat;
    z-index: 0;
  }
`

export const Title =  styled.div.attrs({
    url1:"/images/default/name/header-1-1.png",
    url2:"/images/default/name/header-1-2.png",
    url3:"/images/default/name/header-1-3.png",
})<{h?:string}>`
  position: relative;
  margin: auto;
  height: ${({h})=>h??'35px'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({theme})=>theme.expandColor.color.color10};
  width: fit-content;
  padding: 0 30px;
  overflow: visible;
  z-index: 3;
  & span{
    font-size: 16px;
    z-index: 4;
    font-family: 'ALEO';
  }
  &:before {
    content: "";
    background-size: auto ${({h})=>h??'35px'};
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    image-rendering: pixelated;
    background-image: url(${props=>props.url1}),url(${props=>props.url3});
    background-repeat: no-repeat;
    background-position: 0,100%;
  }
  &:after {
    content: "";
    background-size: 8px ${({h})=>h??'35px'};
    display: block;
    position: absolute;
    left: 20px;
    height: 100%;
    width: calc(100% - 40px);
    background-image: url(${props=>props.url2});
    background-repeat: repeat-x;
    background-position: 50%;
    image-rendering: pixelated;
  }
`

export const GameBtnThemeBase  = styled.div`
  position: relative;
  margin: 10px auto;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: visible;
  z-index: 3;
  padding: 0 5px;
  cursor: pointer;
  & span{
    font-size: 16px;
    color: ${({theme})=>theme.expandColor.color.primary};
    z-index: 4;
    font-family: 'ALEO';
  }
  &:before {
    content: "";
    background-size: auto 30px;
    height: 100%;
    //width: calc(100% + 40px);
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    image-rendering: pixelated;
  
    background-repeat: no-repeat;
    background-position: 0,100%;
  }
  &:after {
    content: "";
    background-size: calc(100% - 99px) 30px;
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
   
    background-repeat: no-repeat;
    background-position: 50%;
    image-rendering: pixelated;
  }
`
export const GameBtnTheme  = styled(GameBtnThemeBase).attrs({
    url1:"/images/default/btn/theme-1-1.png",
    url2:"/images/default/btn/theme-1-2.png",
    url3:"/images/default/btn/theme-1-3.png",
    url4:"/images/default/btn/theme-1-hover-1.png",
    url5:"/images/default/btn/theme-1-hover-2.png",
    url6:"/images/default/btn/theme-1-hover-3.png",
    url7:"/images/default/btn/theme-1-disable-1.png",
    url8:"/images/default/btn/theme-1-disable-2.png",
    url9:"/images/default/btn/theme-1-disable-3.png",
})<{h?:string}>`
  height: ${({h})=>h??'35px'};
  font-size: 14px;
  width: 100%;
  overflow: visible;
  padding: 0 40px;
  cursor: pointer;
  max-width: 460px;
  & span{
    font-size: 16px;
    color: ${({theme})=>theme.expandColor.color.white};
    z-index: 4;
    letter-spacing: 1px;
    white-space: nowrap;
    font-family: 'ALEO';
    // 0.92 0.71 0.38   2: 1.84 1.42 0.76
    text-shadow: 2px 0 0 ${({theme})=>theme.expandColor.color.color10}, 
    1.84px 0.76px 0 ${({theme})=>theme.expandColor.color.color10}, 
    1.42px 1.42px 0 ${({theme})=>theme.expandColor.color.color10}, 
    0.76px 1.84px 0 ${({theme})=>theme.expandColor.color.color10}, 
    0 2px 0 ${({theme})=>theme.expandColor.color.color10}, 
    -0.76px 1.84px 0 ${({theme})=>theme.expandColor.color.color10}, 
    -1.42px 1.42px 0 ${({theme})=>theme.expandColor.color.color10}, 
    -1.84px 0.76px 0 ${({theme})=>theme.expandColor.color.color10},
    -2px 0 0 ${({theme})=>theme.expandColor.color.color10}, 
    -1.84px -0.76px 0 ${({theme})=>theme.expandColor.color.color10}, 
    -1.42px -1.42px 0 ${({theme})=>theme.expandColor.color.color10}, 
    -0.76px -1.84px 0 ${({theme})=>theme.expandColor.color.color10}, 
    0 -2px 0 ${({theme})=>theme.expandColor.color.color10}, 
    0.76px -1.84px 0 ${({theme})=>theme.expandColor.color.color10},
    1.42px -1.42px 0 ${({theme})=>theme.expandColor.color.color10}, 
    1.84px -0.76px 0 ${({theme})=>theme.expandColor.color.color10};
  }
  &:before {
    content: "";
    background-size: auto ${({h})=>h??'35px'};
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    image-rendering: pixelated;
    background-image: url(${props=>props.url1}),url(${props=>props.url3});
    background-repeat: no-repeat;
    background-position: 0,100%;
  }
  &:after {
    content: "";
    background-size: calc(100% - 120px) ${({h})=>h??'35px'};
    display: block;
    position: absolute;
    left:0;
    height: 100%;
    width: 100%;
    background-image: url(${props=>props.url2});
    background-repeat: no-repeat;
    background-position: 50%;
    image-rendering: pixelated;
  }
  &:hover{
    &:before{
      background-image: url(${props=>props.url4}),url(${props=>props.url6});
    }
    &:after{
      background-image: url(${props=>props.url5});
    }
  }
`

export const Back =  styled.div.attrs({
    url1:"/images/default/btn/3-1.png",
    url2:"/images/default/btn/3-2.png",
    url3:"/images/default/btn/3-3.png",
})<{h?:string}>`
  position: relative;
  //margin: auto;
  height: ${({h})=>h??'35px'};
  box-sizing: border-box;
  display: flex;
 
  align-items: center;
  font-size: 14px;
  color: ${({theme})=>theme.expandColor.color.color10};
  width: fit-content;
  padding: 0 10px;
  overflow: visible;
  z-index: 3;
  & span{
    font-size: 16px;
    z-index: 4;
    font-family: 'ALEO';
  }
  &:before {
    content: "";
    background-size: auto ${({h})=>h??'35px'};
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    image-rendering: pixelated;
    background-image: url(${props=>props.url1}),url(${props=>props.url3});
    background-repeat: no-repeat;
    background-position: 0,100%;
  }
  &:after {
    content: "";
    background-size: 8px ${({h})=>h??'35px'};
    display: block;
    position: absolute;
    left: 20px;
    height: 100%;
    width: calc(100% - 40px);
    background-image: url(${props=>props.url2});
    background-repeat: repeat-x;
    background-position: 50%;
    image-rendering: pixelated;
  }
`

export const Next =  styled.div.attrs({
    url1:"/images/default/btn/4-1.png",
    url2:"/images/default/btn/4-2.png",
    url3:"/images/default/btn/4-3.png",
})<{h?:string}>`
  position: relative;
  //margin: auto;
  height: ${({h})=>h??'35px'};
  box-sizing: border-box;
  display: flex;
 
  align-items: center;
  font-size: 14px;
  color: ${({theme})=>theme.expandColor.color.color10};
  width: fit-content;
  padding: 0 10px;
  overflow: visible;
  z-index: 3;
  & span{
    font-size: 16px;
    z-index: 4;
    font-family: 'ALEO';
  }
  &:before {
    content: "";
    background-size: auto ${({h})=>h??'35px'};
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    image-rendering: pixelated;
    background-image: url(${props=>props.url1}),url(${props=>props.url3});
    background-repeat: no-repeat;
    background-position: 0,100%;
  }
  &:after {
    content: "";
    background-size: 8px ${({h})=>h??'35px'};
    display: block;
    position: absolute;
    left: 20px;
    height: 100%;
    width: calc(100% - 40px);
    background-image: url(${props=>props.url2});
    background-repeat: repeat-x;
    background-position: 50%;
    image-rendering: pixelated;
  }
`
export default NpcModalBoxBgUi