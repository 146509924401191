import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import Dialog from "../../../components/Dialog";
import {Next} from "../../../style/UiStyle";

interface Props {
    setLable: (event) => void
}
const Harmony1: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender = (<StyledHarmony>
        <StyledHarmonyRow>
            <StyledHarmonyLeft>
                <img src='images/npc/1.png' alt=''/>
            </StyledHarmonyLeft>
            <StyledHarmonyRight>
                    <span>
                        {t("In order to play Song Of Rising, you’ll need to own some ")}
                        <b>{t("Harmony OKT")}</b>
                        {t(" tokens. Song Of Rising is hosted on the Harmony blockchain, so you’ll need these tokens both for gas fees (the transaction fees charged when trading tokens) and to convert into RUBY.")}
                    </span>
            </StyledHarmonyRight>
        </StyledHarmonyRow>

        <StyledHarmonyContent>
                <span>
                    {t("Gas fees on the Harmony network are very low, so even a single OKT token is enough for gas for quite a while; however, you’ll want plenty of ONE to purchase RUBY and other tokens in-game.")}
                </span>
            <span>
                    {t("You can purchase OKT on a variety of exchanges. I cannot advise you on the pros and cons of each exchange, but some options to consider are Binance, Crypto.com, or a direct purchase from Harmony’s vendor partner on their website.")}
                </span>
            <span>
                    {t("Note that if your vendor asks for a ")}
                <b>{t("wallet address")}</b>
                {t(" to deposit the tokens, you can get this address from Metamask. By default it’s just called Account 1 and you can copy the wallet address by clicking on it. If the vendor requires a ")}
                <b>{t("OKT address")}</b>
                {t(", you can find yours by clicking the menu next to your wallet address and choosing “View in Explorer.” Once you have acquired some OKT tokens, we can move on.")}
                </span>

        </StyledHarmonyContent>
        <StyledHarmonyBotton>
            <StyledHarmonyAlready onClick={()=>{setLable('congratulations')}}>
                <span>{t("I already have Harmony ONE")}</span>
            </StyledHarmonyAlready>
            <StyledHarmonyPage>
                <span>{t("1/3")}</span>
                <StyledHarmonyPageDiv>
                    <div className='show'/>
                    <div className='hide'/>
                    <div className='hide'/>
                </StyledHarmonyPageDiv>
            </StyledHarmonyPage>

            <StyledHarmonyChoose>
                <StyledHarmonyNext onClick={()=>{setLable('harmony2')}}>
                    <span>{t("Next")}</span>
                    <img src="/images/default/btn/4-4.png" alt="" className="left"/>
                </StyledHarmonyNext>
            </StyledHarmonyChoose>
        </StyledHarmonyBotton>
    </StyledHarmony>)
    return <Dialog  DialogChildren={DialogRender} title={t("Harmony ONE Tokens")}/>
    }

const StyledHarmony = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 700px;
    //height: 610px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledHarmonyRow = styled.div`
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 200px;
  }
  & img {
    object-fit: cover;
    height: 120px;
  }
`

const StyledHarmonyRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledHarmonyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 640px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledHarmonyBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledHarmonyPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledHarmonyPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledHarmonyAlready = styled.div`
  width: 100%;
  color: ${({theme})=> theme.expandColor.color.active};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  ${({theme}) => theme.mediaQueries.sm} {
    width: 240px;
  }
  &:hover {
    color: ${({theme})=> theme.expandColor.color.color2};
  }
`
const StyledHarmonyChoose = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    justify-content: end;
  }
`
const StyledHarmonyNext = styled(Next)`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 10px;
  padding-left: 10px;
  cursor: pointer;
  & span {
    padding:0 1px 0 0;
  }
  & .left{
    width: 20px;
    z-index: 1;
    margin-left: 10px;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Harmony1
