import React, {useState} from 'react'
import styled from 'styled-components'
import { message, Button } from 'antd';
import { useTranslation } from 'contexts/Localization'
import BackOrNext from "../../../components/BackOrNext"
import Dialog from  "../../../components/Dialog"

interface Props {
    setLable: (event) => void
}
const Map2: React.FC<Props> = ({setLable}) => {
    const { t } = useTranslation();
    const DialogRender = (
        <StyledMap>
            <StyledMapRow>
                <StyledMapLeft>
                    <img src='images/npc/go-C.png' alt=''/>
                </StyledMapLeft>
                <StyledMapRight>
                    <span>
                        {t("Additionally, the ")}
                        <b>{t("menu")}</b>
                        {t(" in the top right corner allows you to navigate between locations directly without returning to the World Map, provides links to the Song Of Rising Discord server, social media, links to the documentation, and to this tutorial.")}
                    </span>
                </StyledMapRight>
            </StyledMapRow>
            <BackOrNext setLable={setLable} backLable="map1" activeIndex={2} count={3} nextLable="map3"/>

        </StyledMap>
    )
    return <Dialog setLable={setLable} DialogChildren={DialogRender} title={t("World Map")}/>
    }

const StyledMap = styled.div`
  width: 100%;
  padding: 30px;
  //background-color: ${({theme})=> theme.expandColor.color.primary};
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 700px;
    //height: 330px;
  }
  & .title {
    text-align: center;
    font-size: 30px;
    color: ${({theme})=> theme.expandColor.color.active};
    padding: 10px;
  }
`

const StyledMapRow = styled.div`
  // display: flex;
  // flex-direction: column;
  // ${({theme}) => theme.mediaQueries.sm} {
  //   flex-direction: row;
  // }
`
const StyledMapLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.mediaQueries.sm} {
    //width: 200px;
  }
  & img {
    object-fit: cover;
    width: 100%;
  }
`

const StyledMapRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({theme})=> theme.expandColor.color.color10};
  ${({theme}) => theme.mediaQueries.sm} {
    width: 440px;
  }
  & span {
    font-size: 16px;
    padding: 10px 0;
  }
`

const StyledMapBotton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: column;
  ${({theme}) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`
const StyledMapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px 0;
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0;
  }
`
const StyledMapPageDiv = styled.div`
  display: flex;
  align-items: center;
  & div {
    width: 10px;
    height: 3px;
    margin: 10px 5px;

    &.show {
      background-color: #807d7d;
    }

    &.hide {
      background-color: #c9c4c5;
    }
  }
`
const StyledMapBack = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: end;
  margin-right: 0;
  padding-right: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-right: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 0 0 10px;
      transition: all 0.5s;
    }
  }
`
const StyledMapNext = styled.div`
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin-left: 0;
  padding-left: 10px;
  background-color: ${({theme})=> theme.expandColor.color.active};
  cursor: pointer;
  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 10px;
  }
  & span {
    padding:0 1px 0 0;
  }
  &:hover {
    & span {
      padding: 0 10px 0 0;
      transition: all 0.5s;
    }
  }
`
export default Map2
